import React, { useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import useNotification from '@hooks/useNotification';
import { useTranslation } from 'react-i18next';
import { vibrate as handleVibrate } from '@helpers/webApp';

export interface CopyButtonProps {
  text: string;
  alertMessage?: string;
  className?: string;
  iconSrc?: string;
  iconClass?: string;
  noIcon?: boolean;
  iconSize?: number;
  children?: React.ReactNode;
  warning?: string;
}

export default function CopyButton({
  text,
  alertMessage: message,
  className = 'btn btn-link tx-13 p-0 tx-left text-decoration-none',
  iconSrc,
  iconClass = 'tx-muted tx-13 ms-2',
  noIcon = false,
  children = text,
  iconSize = 16,
  warning,
}: CopyButtonProps) {
  const { t } = useTranslation();
  const notify = useNotification();
  const alertMessage = message ?? t('alert.copy');
  const handleCopy = useCallback(() => {
    const showMainToast = () => notify(alertMessage + '', { duration: 5000 });
    const showWarnToast = () =>
      notify(warning + '', {
        duration: 60000,
        type: 'warning',
        closable: true,
      });
    if (warning) showWarnToast();
    showMainToast();
    handleVibrate('medium');
  }, [alertMessage]);

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <div className={className}>
        {children}
        {!noIcon && (
          <span className={iconClass}>
            {iconSrc ? (
              <img src={iconSrc} alt="copy" />
            ) : (
              <svg
                width={iconSize}
                height={iconSize}
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.95473 15.3163H9.27862C9.86388 15.3163 10.3028 15.1672 10.5954 14.8689C10.8881 14.5706 11.0344 14.127 11.0344 13.5379V9.06362H7.08111C6.71486 9.06362 6.44488 8.97583 6.27119 8.80025C6.09751 8.62468 6.01067 8.35565 6.01067 7.99317V3.89261H3.95473C3.36948 3.89261 2.93054 4.04175 2.63791 4.34004C2.34529 4.63833 2.19897 5.08199 2.19897 5.67102V13.5379C2.19897 14.1307 2.34529 14.5753 2.63791 14.8717C2.93054 15.1681 3.36948 15.3163 3.95473 15.3163ZM7.07545 8.28768H10.9325C10.9023 8.17818 10.8532 8.06869 10.7852 7.95919C10.7172 7.84969 10.6191 7.73074 10.4907 7.60233L7.46624 4.52128C7.34164 4.3929 7.22365 4.28718 7.11226 4.20412C7.00087 4.12105 6.89043 4.06441 6.78093 4.0342V7.9988C6.78093 8.19139 6.8791 8.28768 7.07545 8.28768ZM11.8047 12.4902H12.0256C12.3163 12.4902 12.5711 12.4524 12.7901 12.3769C13.0091 12.3014 13.1923 12.19 13.3395 12.0427C13.4868 11.8955 13.5972 11.7104 13.6709 11.4877C13.7445 11.2649 13.7813 11.0063 13.7813 10.7118V5.33686H10.6493C10.351 5.33686 10.1216 5.25662 9.96112 5.09615C9.80067 4.93568 9.72044 4.70818 9.72044 4.41367V1.06641H6.70164C6.12772 1.06641 5.69161 1.21366 5.39332 1.50818C5.09503 1.80269 4.94589 2.23314 4.94589 2.79951V3.12234H5.76712C6.16358 3.12234 6.52795 3.17898 6.86023 3.29225C7.1925 3.40553 7.51722 3.62075 7.83439 3.93792L11.0514 7.14927C11.3686 7.46643 11.5734 7.79397 11.6659 8.1319C11.7584 8.46983 11.8047 8.83136 11.8047 9.2165V12.4902ZM10.7569 4.62889H13.7133C13.6983 4.53827 13.6614 4.45143 13.6029 4.36836C13.5444 4.2853 13.4641 4.19279 13.3622 4.09084L10.9664 1.64977C10.8683 1.54782 10.7767 1.46664 10.6918 1.40623C10.6068 1.34582 10.5209 1.30995 10.434 1.29862L10.4397 4.3004C10.4397 4.5194 10.5454 4.62889 10.7569 4.62889Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </span>
        )}
      </div>
    </CopyToClipboard>
  );
}
