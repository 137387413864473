import React from 'react';
import usePopup from '@hooks/usePopup';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import demoChart from '@assets/images/whale-signals/whale-demo-chart.png';

export const useWhaleSignalsInfoModal = () => {
  const showPopup = usePopup();
  const { t } = useTranslation();
  const [w] = useAmplitude();

  return w(
    () =>
      showPopup({
        message: (
          <div className="mt-3">
            <img src={demoChart} alt="Demo Chart" />
            <div className="d-flex flex-column gap-12px align-items-center my-4">
              <h1 className="m-0">{t('common.whale-signals')}</h1>
              <span className="tx-muted tx-13">
                {t('signal.whale-signals-info-description')}
              </span>
            </div>
            <div className="tx-center tx-semibold mb-3">
              {t('signal.labels')}
            </div>
            <div className="d-flex gap-2 tx-left">
              <div className="d-flex flex-column wd-50p rounded-10-px bg-semi-transparent-10 pd-12">
                <div className="d-flex align-items-center justify-content-center ht-32 wd-32 rounded-circle me-auto bg-gray-900">
                  🐋
                </div>
                <div className="tx-muted tx-13">
                  {t('signal.wallets-with-balance-more-than-30eth')}
                </div>
              </div>
              <div className="d-flex flex-column wd-50p rounded-10-px bg-semi-transparent-10 pd-12">
                <div className="d-flex align-items-center justify-content-center ht-32 wd-32 rounded-circle me-auto bg-gray-900">
                  ⭐
                </div>
                <div className="tx-muted tx-13">
                  {t('signal.known-public-wallets')}
                </div>
              </div>
            </div>

            <div className="my-2 tx-left d-flex flex-column rounded-10-px bg-semi-transparent-10 pd-12">
              <div className="d-flex align-items-center justify-content-center ht-32 wd-32 rounded-circle me-auto bg-gray-900">
                🍀
              </div>
              <div className="tx-muted tx-13">{t('signal.fresh-wallets')}</div>
            </div>

            <div className="d-flex gap-2 tx-left">
              <div className="d-flex flex-column wd-50p gap-2 rounded-10-px bg-semi-transparent-10 pd-12">
                <div className="d-flex p-2 wd-100p flex-column align-items-center justify-content-center rounded-10-px me-auto bg-gray-900">
                  <span className="tx-muted tx-13">
                    {t('signal.current-price-full')}
                  </span>
                  <span className="tx-danger tx-13">-112%</span>
                </div>
                <div className="tx-muted tx-11">
                  {t('signal.current-price-change-description')}
                </div>
              </div>
              <div className="d-flex flex-column wd-50p gap-2 rounded-10-px bg-semi-transparent-10 pd-12">
                <div className="d-flex p-2 wd-100p flex-column align-items-center justify-content-center rounded-10-px me-auto bg-gray-900">
                  <span className="tx-muted tx-13">
                    {t('signal.max-price-full')}
                  </span>
                  <span className="tx-success tx-13">+234%</span>
                </div>
                <div className="tx-muted tx-11">
                  {t('signal.max-price-change-description')}
                </div>
              </div>
            </div>

            <div className="my-2 tx-left d-flex flex-column rounded-10-px bg-semi-transparent-10 pd-12 mb-4">
              <div className="d-flex align-items-center justify-content-center ht-32 wd-35 rounded-circle me-auto bg-gray-900">
                <span className="tx-bold tx-success">+7</span>
              </div>
              <div className="tx-muted tx-13">
                {t('signal.change-in-last-hour')}
              </div>
            </div>
          </div>
        ),
        buttons: [
          {
            text: t('common.close'),
            type: 'secondary',
            id: 'cancel',
          },
        ],
      }),
    AmplitudeEvent.WHALE_LOCATOR_INFO_CLICKED,
  );
};
