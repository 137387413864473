import { action, computed, makeAutoObservable, observable, reaction } from 'mobx';
import { OrderTrigger } from 'types/order/order.enum';
import { OrderResponse } from 'types/order/order.response';
import BigNumber from 'bignumber.js';
import { AxiosError } from 'axios';
import api from '@helpers/api';
import { BaseChainToken, Blockchain } from 'types/enums';
import { defaultOrderSettings } from './settings-store';
import { TpSlOrders, TpSlValue } from '@stores/token-trade';
import { IOrderSettings, QuickMode, QuickModeSave } from '../types';
import { toast } from 'react-hot-toast';
import { AccountStore } from '@stores/account-store';
import { ChainId } from '../constants';
import { CommandResultResponse } from '../types/command-result.response';
import { hasOwnProperty } from '@helpers/object';
import { chainToWallet } from '@helpers/chains';

export class QuickTradeStore {
  private _accountStore: AccountStore;

  @observable
  private _init: boolean | null;

  @observable
  private _id: string | null;

  @observable
  private _chain: string | null;

  @observable
  private _currency: number | null;

  @observable
  private _address: string | null;

  @observable
  private _order: OrderResponse | null;

  @observable
  private _amount: string | number | null = null;

  @observable
  private _amountUsd: string | number | null = null;

  @observable
  private _needConfirm: boolean = true;

  @observable
  private _walletId: string | null = null;

  @observable
  private _triggerType: OrderTrigger | null = OrderTrigger.PRICE_IN_USD;

  @observable
  private _emptyInputsLightOn: boolean = false;

  @observable
  private _tpSlOrders: TpSlOrders = { takeProfits: null, stopLosses: null };

  @observable
  private _name: string | null = null;

  @observable
  private _enabled: boolean = false;

  @observable
  private _orderSettings: IOrderSettings | null = null;

  @observable
  private _stopLossSettings: IOrderSettings | null = null;

  @observable
  private _takeProfitSettings: IOrderSettings | null = null;

  @observable
  private _quickTradeSettings: QuickMode[] = [];

  @observable
  private _quickTradeSettingsLoaded: boolean = false;

  constructor(accountStore: AccountStore) {
    this._accountStore = accountStore;

    reaction(
      () => accountStore.currentWallet,
      () => this.getQuickTradeSettings(),
    );

    makeAutoObservable(this);
  }

  get isInit() {
    return this._init;
  }

  get id() {
    return this._id;
  }

  get chain() {
    return this._chain;
  }

  get currency() {
    return this._currency;
  }

  get address() {
    return this._address;
  }

  get order() {
    return this._order;
  }

  get amount() {
    return isNaN(Number(this._amount)) ? 0 : this._amount;
  }

  get amountUsd() {
    return isNaN(Number(this._amountUsd)) ? 0 : this._amountUsd;
  }

  get triggerType() {
    return this._triggerType;
  }

  get isStopLossesEnabled() {
    return Array.isArray(this._tpSlOrders.stopLosses);
  }

  get isTakeProfitsEnabled() {
    return Array.isArray(this._tpSlOrders.takeProfits);
  }

  get walletId() {
    return this._walletId;
  }

  @computed
  wallet() {
    return this._accountStore.wallets.find((w) => w.id === this._walletId);
  }

  @action
  setWalletId(v: string) {
    this._walletId = v;
  }

  get name() {
    return this._name;
  }

  @action
  setName(v: string) {
    this._name = v;
  }

  get tpSlOrders(): TpSlOrders | undefined {
    if (!(this.isStopLossesEnabled || this.isTakeProfitsEnabled)) return;
    const { takeProfits, stopLosses } = this._tpSlOrders;

    return {
      takeProfits:
        takeProfits?.map((o) => ({
          ...o,
          settings: this._takeProfitSettings || defaultOrderSettings,
        })) ?? null,
      stopLosses:
        stopLosses?.map((o) => ({
          ...o,
          settings: this._stopLossSettings || defaultOrderSettings,
        })) ?? null,
    };
  }

  get orderSettings() {
    return this._orderSettings || defaultOrderSettings;
  }

  get stopLossSettings() {
    return this._stopLossSettings || defaultOrderSettings;
  }

  get takeProfitSettings() {
    return this._takeProfitSettings || defaultOrderSettings;
  }

  get stopLosses() {
    return this._tpSlOrders.stopLosses;
  }

  get takeProfits() {
    return this._tpSlOrders.takeProfits;
  }

  get needConfirm() {
    return this._needConfirm;
  }

  get emptyInputsLightOn() {
    return this._emptyInputsLightOn;
  }

  get stopLossesError() {
    const hasEmpty =
      this.emptyInputsLightOn &&
      this._tpSlOrders.stopLosses
        ?.flatMap((tp) => tp && Object.values(tp))
        .some((tp) => !tp);

    const hasExceededSum =
      Number(
        this._tpSlOrders.stopLosses?.reduce(
          (accumulator, currentValue) =>
            accumulator + (Number(currentValue.tokenPercents) || 0),
          0,
        ),
      ) > 100;

    return [
      hasExceededSum ? 'order.value-amount-should-not-exceed-100' : null,
      hasEmpty ? 'order.all-fields-required-error' : null,
    ];
  }

  get takeProfitsError() {
    const hasEmpty =
      this.emptyInputsLightOn &&
      this._tpSlOrders.takeProfits
        ?.flatMap((tp) => tp && Object.values(tp))
        .some((tp) => !tp);

    const hasExceededSum =
      Number(
        this._tpSlOrders.takeProfits?.reduce(
          (accumulator, currentValue) =>
            accumulator + (Number(currentValue.tokenPercents) || 0),
          0,
        ),
      ) > 100;

    return [
      hasExceededSum ? 'order.value-amount-should-not-exceed-100' : null,
      hasEmpty ? 'order.all-fields-required-error' : null,
    ];
  }

  @action
  initialize(id?: string) {
    if (!this.quickTradeSettingsLoaded) {
      this.getQuickTradeSettings(id);
      return;
    }

    if (id && (!this.isInit || this.id !== id)) {
      const found = this._quickTradeSettings.find((s) => s.id === id);
      if (found) {
        this._id = id;
        this._orderSettings = JSON.parse(JSON.stringify(found.orderSettings));
        this._amount = found.valueIn;
        const usd = new BigNumber(found.valueIn).multipliedBy(this._currency || 0);
        this._amountUsd = usd.isLessThan(0.01) ? usd.toString() : usd.toFixed(2);
        this._needConfirm = found.needConfirm;
        this.setWalletId(found.walletId);
        this._name = found.name;
        this._enabled = found.enabled;
        // if (found.walletId !== this._accountStore.currentWallet?.id) {
        //   this._accountStore.setCurrentWalletById(found.walletId);
        // }
        this.switchTakeProfits(!!found.orders.takeProfits?.length);
        if (found.orders.takeProfits?.length) {
          this.setTakeProfits(found.orders.takeProfits);
        }
        this.switchStopLosses(!!found.orders.stopLosses?.length);
        if (found.orders.stopLosses?.length) {
          this.setStopLosses(found.orders.stopLosses);
        }
      } else {
        this._id = null;
      }
    } else {
      let wallet = this._accountStore.currentWallet;
      if (!wallet && this._chain) {
        const type = chainToWallet(this._chain as ChainId);
        wallet = this._accountStore.wallets.find((w) => w.type === type) || null;
      }
      this._walletId = wallet?.id || null;
    }
    this.setIsInit(true);
  }

  @action
  setOrderSettings(v: IOrderSettings) {
    this._orderSettings = v;
  }

  @action
  setStopLossSettings(v: IOrderSettings) {
    this._stopLossSettings = v;
  }

  @action
  setTakeProfitSettings(v: IOrderSettings) {
    this._takeProfitSettings = v;
  }

  @action
  setChain(value: string) {
    this._chain = value;
    this.getCurrency();
  }

  @action
  setIsInit(v: boolean) {
    this._init = v;
  }

  @action
  setAmount(v: typeof this.amount) {
    this._amount = v;
  }

  @action
  setAmountUsd(v: typeof this.amountUsd) {
    this._amountUsd = v;
  }

  @action
  setNeedConfirm(v: boolean) {
    this._needConfirm = v;
  }

  @action
  setTriggerType(v: typeof this.triggerType) {
    this._triggerType = v;
  }

  @action
  switchTakeProfits(v: boolean) {
    this._tpSlOrders.takeProfits = v
      ? [{ profitPercents: null, tokenPercents: null }]
      : null;
  }

  @action
  switchStopLosses(v: boolean) {
    this._tpSlOrders.stopLosses = v
      ? [{ lossPercents: null, tokenPercents: null }]
      : null;
  }

  @action
  addTakeProfitValue() {
    this._tpSlOrders.takeProfits!.push({
      profitPercents: null,
      tokenPercents: null,
    });
  }

  @action
  setTakeProfits(values: TpSlValue[]) {
    this._tpSlOrders.takeProfits = values;
  }

  @action
  addStopLossValue() {
    this._tpSlOrders.stopLosses!.push({
      lossPercents: null,
      tokenPercents: null,
    });
  }

  @action
  setStopLosses(values: TpSlValue[]) {
    this._tpSlOrders.stopLosses = values;
  }

  @action
  removeTakeProfitValue(idx: number) {
    this._tpSlOrders.takeProfits!.splice(idx, 1);
  }

  @action
  removeStopLossValue(idx: number) {
    this._tpSlOrders.stopLosses!.splice(idx, 1);
  }

  @action
  setTakeProfitValue(idx: number) {
    return (input: 'percents' | 'value') => (value: string | number | null) => {
      if (
        this._tpSlOrders.takeProfits &&
        Array.isArray(this._tpSlOrders.takeProfits)
      ) {
        if (input === 'percents') {
          this._tpSlOrders.takeProfits[idx]!.profitPercents = value as number;
        } else if (input === 'value') {
          this._tpSlOrders.takeProfits[idx]!.tokenPercents = value as number;
        }
      }
    };
  }

  @action
  setStopLossValue(idx: number) {
    return (input: 'percents' | 'value') => (value: string | number | null) => {
      if (
        this._tpSlOrders.stopLosses &&
        Array.isArray(this._tpSlOrders.stopLosses)
      ) {
        if (input === 'percents') {
          this._tpSlOrders.stopLosses[idx]!.lossPercents = value as number;
        } else if (input === 'value') {
          this._tpSlOrders.stopLosses[idx]!.tokenPercents = value as number;
        }
      }
    };
  }

  @action
  setEmptyInputsLightOn() {
    this._emptyInputsLightOn = true;
  }

  @action
  async getOrder(id: string) {
    return api<OrderResponse>({
      method: 'get',
      path: `/order/${id}`,
    })
      .then((response) => {
        this._order = response;
      })
      .catch((response) => {
        toast.error(response.response.data.message);
      });
  }

  @action
  setCurrency(v: number) {
    this._currency = v;
  }

  @action
  async getCurrency() {
    return api<any>({
      method: 'get',
      path: `/token/price`,
    })
      .then((response) => {
        //@ts-ignore
        const baseToken = BaseChainToken[this._chain!];
        this.setCurrency(response[baseToken]);
        // this._currency = response[baseToken];
        if (this._init) {
          this.setAmountUsdByAmount();
        }
        return response;
      })
      .catch((response) => {
        toast.error(response.response.data.message);
      });
  }

  @action
  setAmountUsdByAmount() {
    const usd = new BigNumber(this._amount || 0).multipliedBy(this._currency || 0);
    this._amountUsd = usd.isLessThan(0.01) ? usd.toString() : usd.toFixed(2);
  }

  get quickTradeSettings() {
    return this._quickTradeSettings;
  }

  get quickTradeSettingsLoaded() {
    return this._quickTradeSettingsLoaded;
  }

  @action
  async getQuickTradeSettings(id?: string) {
    return api<QuickMode[]>({
      method: 'get',
      path: `/quick-mode`,
    })
      .then((response) => {
        this._quickTradeSettings = response;
        this._quickTradeSettingsLoaded = true;
        if (id) {
          this.initialize(id);
        }
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  get quickModeData(): QuickModeSave {
    const data: QuickModeSave = {
      walletId: this._walletId || this._accountStore.currentWallet?.id || '',
      blockchain: this._chain as Blockchain || this._accountStore.network as unknown as Blockchain,
      orders: {
        takeProfits: (this._tpSlOrders?.takeProfits || []).map((o) => ({...o, settings: this._takeProfitSettings || undefined})),
        stopLosses: (this._tpSlOrders?.stopLosses || []).map((o) => ({...o, settings: this._stopLossSettings || undefined})),
        trigger: OrderTrigger.PRICE_IN_USD,
      },
      orderSettings: this._orderSettings || defaultOrderSettings,
      valueIn: this._amount?.toString() || '',
      needConfirm: this._needConfirm,
      name: this._name || '',
      enabled: this._enabled,
    };
    if (this._id) {
      data.id = this._id;
    }
    return data;
  }

  @action
  async createPreset() {
    return api<QuickMode[]>({
      method: 'post',
      path: `/quick-mode`,
      data: this.quickModeData,
    })
      .then((response) => {
        this._quickTradeSettings = response;
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async updatePreset(data?: QuickModeSave) {
    if (!data && !this.id) {
      return this.createPreset();
    }

    const updateData = data || this.quickModeData;

    return api<QuickMode[]>({
      method: 'patch',
      path: `/quick-mode/${data?.id || this.id}`,
      data: {
        ...updateData,
        valueIn: new BigNumber(updateData.valueIn).toString(),
      },
    })
      .then((response) => {
        this._quickTradeSettings = response;
        return response;
      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
  }

  @action
  async deletePreset(id: string) {
    const result = await api<CommandResultResponse>({
      method: 'delete',
      path: `/quick-mode/${id}`,
    })
      .then((response) => {
        return response && hasOwnProperty(response, 'success') && response.success;

      })
      .catch((response) => {
        if (response instanceof AxiosError && response.response?.data) {
          console.error(response.response.data);
        } else {
          console.error(response);
        }
        return response.response;
      });
    if (result) {
      await this.getQuickTradeSettings();
      return true;
    }
    return result;
  }

  @action
  async reset() {
    this._init = null;
    this._id = null;
    this._chain = null;
    this._currency = null;
    this._address = null;
    this._order = null;
    this._walletId = null;
    this._needConfirm = false;
    this._orderSettings = null;
    this._stopLossSettings = null;
    this._takeProfitSettings = null;
    this._amount = null;
    this._amountUsd = null;
    this._name = null;
    this._triggerType = OrderTrigger.PRICE_IN_USD;
    this._tpSlOrders = { takeProfits: null, stopLosses: null };
    this._emptyInputsLightOn = false;
  }
}
