import React, { useMemo } from 'react';
import cn from 'classnames';
import info from 'assets/icons/info-circle.svg';
import { observer } from 'mobx-react-lite';
import InputGroup from '@components/common/InputGroup';
import { AlphaOrderLastLevelReachStrategy } from 'types/order/order.enum';
import usePopup from '@hooks/usePopup';
import { useTranslation } from 'react-i18next';
import LevelStep from '@components/AlphaOrder/LevelStep';
import { useStores } from '@hooks/useStores';
import { AlphaOrderModel } from '@pages/LimitOrders/models/AlphaOrderModel';

type Props = {
  state: AlphaOrderModel;
};

const SubtotalLevelsGroup = (props: Props) => {
  const { tokenTrade } = useStores();
  const { state } = props;
  const {
    errors,
    levelsQty,
    alphaLevels,
    lastLevelReachStrategy,
    trailingStep,
  } = state;
  const { emptyInputsLightOn } = tokenTrade;

  const { t } = useTranslation();
  const showPopup = usePopup();

  const showStrategyInfo = ([label, description]: string[]) =>
    showPopup({
      message: (
        <div className="d-flex flex-column gap-1 mb-3">
          <div className="tx-semibold">{label}</div>
          <div className="tx-13 tx-muted">{description}</div>
        </div>
      ),
      buttons: [
        {
          text: t('common.close'),
          type: 'secondary',
          id: 'cancel',
        },
      ],
    });

  const strategies: [string, AlphaOrderLastLevelReachStrategy][] = [
    ['order.alpha.auto-close', AlphaOrderLastLevelReachStrategy.EXECUTE_ORDER],
    [
      'order.alpha.wait-reverse',
      AlphaOrderLastLevelReachStrategy.EXECUTE_DEFAULT,
    ],
    [
      'order.alpha.wait-best-price',
      AlphaOrderLastLevelReachStrategy.EXECUTION_TRAILING,
    ],
  ];

  const disabled = useMemo(
    () => !!errors.excessLimitError,
    [errors.excessLimitError],
  );

  const hasErrors = useMemo(() => {
    const {
      levelOrderError,
      excessLimitError,
      levelsOutOfLimits,
      levelsEmptyError,
    } = errors;

    return ![
      levelOrderError,
      excessLimitError,
      ...levelsOutOfLimits,
      ...levelsEmptyError,
    ].every((e) => [-1, false, null].includes(e));
  }, [errors]);

  return (
    <div className="d-flex flex-column gap-12px">
      <div className="tx-15 tx-semibold">
        {t('order.alpha.number-of-intermediate-levels')}
      </div>
      <div className="d-flex gap-2px">
        {Array.from(Array(11).keys())
          .slice(2)
          .map((n) => (
            <div
              className={cn([
                'tx-13 tx-semibold pd-x-2 pd-y-8 btn btn-semi-10 wd-100p',
                'rounded-2px',
                'bd-solid',
                'bd-1',
                disabled && 'disabled',
                n === 2 && 'rounded-l-8-px',
                n === 10 && 'rounded-r-8-px',
                n === levelsQty
                  ? 'btn-primary-10 bd-primary'
                  : 'btn-semi-10 bd-transparent',
                emptyInputsLightOn &&
                  errors.levelsQtyError &&
                  'btn-danger-10 bd-danger',
              ])}
              key={n}
              onClick={() => state.setLevelsQty(n)}
            >
              {n}
            </div>
          ))}
      </div>
      {levelsQty && (
        <div>
          <div className="d-flex gap-2 mb-1">
            <div className="d-flex wd-65p tx-13 tx-muted">Цена</div>
            <div className="d-flex wd-35p tx-13 tx-muted">Изменение</div>
          </div>
          <div className="d-flex flex-column gap-2">
            {(alphaLevels as Array<[string, number]>).map(
              ([usdValue, percentage], idx) => {
                const isInValid =
                  [
                    errors.levelOrderError,
                    ...errors.levelsOutOfLimits,
                    ...errors.levelsEmptyError,
                  ].includes(idx) && !disabled;

                return (
                  <div
                    className="d-flex gap-2"
                    key={`subtotal-level-${idx}-of-${levelsQty}`}
                  >
                    <div className="d-flex wd-65p">
                      <InputGroup
                        isInValid={isInValid}
                        disabled={![0, levelsQty - 1].includes(idx) && disabled}
                        inputClassName={cn([
                          'form-control',
                          'appearance-none',
                          'py-1',
                          'ht-32',
                          disabled && 'tx-muted',
                        ])}
                        unitClassName="ht-32"
                        inputMode="decimal"
                        onChange={(v) =>
                          state.editAlphaLevel(idx, [v, undefined])
                        }
                        value={usdValue}
                        placeholder="0$"
                        unit="$"
                      />
                    </div>
                    <div className="d-flex wd-35p">
                      <InputGroup
                        isInValid={isInValid}
                        disabled={![0, levelsQty - 1].includes(idx) && disabled}
                        inputClassName={cn([
                          'form-control',
                          'appearance-none',
                          'py-1',
                          'ht-32',
                          disabled && 'tx-muted',
                        ])}
                        unitClassName="ht-32"
                        inputMode="decimal"
                        noCross
                        onChange={(v) =>
                          state.editAlphaLevel(idx, [undefined, v])
                        }
                        value={percentage}
                        placeholder="0%"
                        unit="%"
                      />
                    </div>
                  </div>
                );
              },
            )}
          </div>

          {!errors.excessLimitError && hasErrors && (
            <div className="flex-row justify-content-between card bg-semi-danger-10 mt-2 pd-12 py-2">
              <span className="tx-13 tx-danger">
                {t('order.alpha.range-error')}
              </span>
              <span
                onClick={() => state.setLevelsQty(levelsQty)}
                className="tx-13 tx-danger tx-semibold text-decoration-underline"
              >
                {t('common.reset')}
              </span>
            </div>
          )}

          <div className="d-flex flex-column gap-12px mg-t-12">
            <span className="tx-15 tx-semibold">
              {t('order.alpha.action-on-lower-threshold')}
            </span>
            <div className="d-flex flex-column gap-2">
              {strategies.map(([label, key]) => (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={key}
                >
                  <div
                    className={cn(
                      'd-flex gap-2 align-items-center',
                      disabled && 'opacity-02',
                    )}
                    onClick={() =>
                      !disabled && state.setLastLevelReachStrategy(key)
                    }
                  >
                    <div
                      className={cn([
                        'check',
                        lastLevelReachStrategy === key && 'checked',
                      ])}
                    />
                    <span className="tx-15">{t(label)}</span>
                  </div>
                  <button
                    onClick={() =>
                      showStrategyInfo([t(label), t(`${label}-description`)])
                    }
                    className="d-flex btn pd-0"
                  >
                    <img src={info} alt="Info" width={20} height={20} />
                  </button>
                </div>
              ))}
            </div>
          </div>
          {lastLevelReachStrategy ===
            AlphaOrderLastLevelReachStrategy.EXECUTION_TRAILING && (
            <div className="d-flex flex-column gap-1 mg-t-12">
              <span className="tx-muted tx-13">
                {t('order.alpha.additional-intermediate-step')}
              </span>
              <LevelStep
                isInValid={emptyInputsLightOn && errors.trailingStepError}
                disabled={disabled}
                value={trailingStep!}
                onChange={(v) => state.setTrailingStep(v)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(SubtotalLevelsGroup);
