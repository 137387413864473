import React, { useEffect, useMemo, useState } from 'react';
import { allowedChains, chainLogo, chainName } from '@helpers/chains';
import arrow from '@assets/icons/chevron-down.svg';
import { Modal } from 'react-bootstrap';
import { ChainId } from '../constants';
import { useTranslation } from 'react-i18next';
import { toKebabCase } from '@helpers/formatters';
import Vibrutton from '@components/common/Vibrutton';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Badge from '@components/common/Badge';

interface NetworkSelectionProps {
  network: ChainId;
  onChange: (network: ChainId) => void;
  allNetworks?: boolean;
  children?: React.ReactNode;
  disabledNetworks?: ChainId[];
  enabledNetworks?: ChainId[];
  title?: string;
}

const NetworkSelection = (props: NetworkSelectionProps) => {
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const {
    network,
    onChange,
    allNetworks = false,
    disabledNetworks = [],
    enabledNetworks = [],
    title = t('common.networks'),
    children,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleChange = (value: ChainId) => {
    setShowModal(false);
    if (onChange) {
      onChange(value);
    }
  };

  const networks = useMemo(() => {
    let list = allowedChains.slice();
    if (!allNetworks) {
      list = list.filter((value) => value !== ChainId.ALL);
    }
    if (enabledNetworks.length) {
      return list.filter((value) => enabledNetworks.includes(value));
    }
    if (disabledNetworks.length) {
      return list.filter((value) => !disabledNetworks.includes(value));
    }
    return list;
  }, [disabledNetworks, enabledNetworks, allNetworks]);

  useEffect(() => {
    if (network && !networks.includes(network)) {
      onChange(networks[0]);
    }
  }, [network, networks]);

  return (
    <>
      <Vibrutton
        className="d-flex btn btn-transparent p-0 tx-left"
        onClick={w(
          () => setShowModal(true),
          AmplitudeEvent.NETWORK_SELECTION_CLICKED,
        )}
      >
        {children ? (
          children
        ) : (
          <>
            <img
              src={chainLogo(network)}
              alt={network}
              className="wd-40 ht-40 me-3"
            />

            <div>
              <div className="tx-12 tx-muted">{t('common.network')}</div>
              <div className="tx-17">
                {t(`common.${toKebabCase(chainName(network))}`, {
                  defaultValue: chainName(network),
                })}
                <img src={arrow} alt="Choose network" />
              </div>
            </div>
          </>
        )}
      </Vibrutton>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          {networks.map((value, index) => (
            <Vibrutton
              className={`btn ${network === value ? 'btn-semi-10 bg-semi-transparent-10' : 'btn-transparent'} d-flex align-items-center px-2 py-1 wd-100p tx-14 ${index !== networks.length - 1 ? 'mb-3' : ''}`}
              onClick={() => handleChange(value)}
              key={`network-selection-item-${value}`}
            >
              <div
                className={`${value === ChainId.ALL ? 'd-none' : 'd-flex'} wd-25 ht-25 align-items-center justify-content-center rounded-circle me-2 py-3`}
              >
                <img src={chainLogo(value)} alt={value} width={24} />
              </div>
              {t(`common.${toKebabCase(chainName(value))}`, {
                defaultValue: chainName(value),
              })}
              {value === ChainId.SUI && <Badge label={t('common.new')} className="tx-12 ms-auto me-0" />}
            </Vibrutton>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NetworkSelection;
