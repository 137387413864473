import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { initUtils } from '@telegram-apps/sdk';
import { hasOwnProperty } from '@helpers/object';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { useStores } from '@hooks/useStores';
import { useNavigate } from 'react-router-dom';
import useNotification from '@hooks/useNotification';
import { OrderType, QuickMode } from '../../../types';
import { useTranslation } from 'react-i18next';
import useResponseHandler from '@hooks/useResponseHandler';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { ChainId, PageRoutes } from '../../../constants';
import filters from '@assets/icons/filters-dark.svg';
import { ResearchPairResponse } from '../../../types/research/research-pair.response';
import QuickTradeConfirmation from '@pages/LimitOrders/components/QuickTradeConfirmation';
import IconWithLoading from '@components/common/IconWithLoading';

const QuickTradeButtons = () => {
  const { accountStore, ordersStore, quickTradeStore } = useStores();
  const utils = initUtils();
  const navigate = useNavigate();
  const [w] = useAmplitude();
  const { network } = accountStore;
  const { quickTradeSettings, quickTradeSettingsLoaded } = quickTradeStore;
  const notify = useNotification();
  const { t } = useTranslation();
  const handleResponse = useResponseHandler();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const WebApp = useWebApp();
  const [token, setToken] = useState<ResearchPairResponse | null>(null);

  const enabledSettings = useMemo(() => {
    return quickTradeSettings.filter(
      (s) => s.enabled && (s.blockchain as unknown as ChainId) === network,
    );
  }, [quickTradeSettings, network]);

  const networkSettings = useMemo(() => {
    if (token) {
      return enabledSettings.find((x) => x.blockchain === token.blockchain);
    }
    return undefined;
  }, [token, enabledSettings]);

  const handleOrder = (item: ResearchPairResponse, settings: QuickMode) => {
    try {
      ordersStore
        .createOrder({
          tpSlOrders: {
            takeProfits: settings.orders.takeProfits || null,
            stopLosses: settings.orders.stopLosses || null,
          },
          settings: {
            ...settings.orderSettings,
            //@ts-ignore
            extraGasPricePercent:
              settings.orderSettings?.extraGasPricePercent !== null
                ? settings.orderSettings?.extraGasPricePercent
                : undefined,
            //@ts-ignore
            slippage:
              settings.orderSettings?.slippage !== null
                ? settings.orderSettings?.slippage
                : undefined,
          },
          blockchain: item.blockchain,
          type: OrderType.MARKET_BUY,
          trigger: undefined,
          targetTriggerValue: undefined,
          pairAddress: item.pairAddress,
          valueIn: settings.valueIn,
          walletId: settings.walletId,
        })
        .then((response) => {
          if (response && hasOwnProperty(response, 'pairAddress')) {
            accountStore.setCurrentWalletById(settings.walletId);
            accountStore.setNetwork(settings.blockchain as unknown as ChainId);
            navigate(
              `/limit-orders/${response.blockchain}/${response.pairAddress}/${response.pairId}`,
              { replace: true },
            );
            notify(t('order.order-success'), { type: 'success' });
          } else {
            setIsLoading(false);
            handleResponse(
              response?.data?.message || t('error-occurred'),
              true,
            );
          }
        })
        .catch((e) => {
          setIsLoading(false);
          console.error(e);
          handleResponse(e.response);
        });
    } catch (e) {
      setIsLoading(false);
      console.error('CreateOrder Error: ', e);
      notify(String(e), { type: 'danger' });
    }
  };

  const readClipboard = async () => {
    if (!quickTradeSettingsLoaded) {
      notify(t('settings.loading'), { type: 'warning' });
      return;
    }
    console.log('Reading clipboard');
    let result = '';
    try {
      if (document.hasFocus()) {
        result = await navigator.clipboard.readText();
        console.log('second result', result);
      } else {
        result = (await utils.readTextFromClipboard()) || '';
        console.log('first result', result);
      }
    } catch (e) {
      console.error(e);
    }
    if (!result) {
      try {
        result = (await utils.readTextFromClipboard()) || '';
        console.log('first 2 result', result);
      } catch (e) {
        console.error(e);
      }
    }
    // if (
    //   WebApp &&
    //   greaterThan(WebApp.version, '6.0')
    // ) {
    //   result = await utils.readTextFromClipboard() || '';
    //   console.log('first result', result);
    // } else if (document.hasFocus()) {
    //   result = await navigator.clipboard.readText();
    //   console.log('second result', result);
    // }
    console.log('result', result);
    if (result) {
      setIsLoading(true);
      accountStore
        .getPairDetails(result)
        .then((response) => {
          console.log('search response', response);
          if (
            response &&
            response.length &&
            response[0] &&
            hasOwnProperty(response[0], 'pairAddress')
          ) {
            console.log('token found', response[0]);
            const settings = enabledSettings.find(
              (x) => x.blockchain === response[0].blockchain,
            );
            if (settings) {
              console.log('settings found', settings);
              if (!settings.needConfirm) {
                handleOrder(response[0], settings);
              } else {
                setToken(response[0]);
              }
            } else {
              setIsLoading(false);
              notify(t('quick-trade.no-settings'), { type: 'danger' });
            }
          } else {
            setIsLoading(false);
            w(
              () => {
                notify(t('quick-trade.not-found'));
              },
              AmplitudeEvent.TOKEN_SEARCH_NOT_FOUND,
              {
                address: result,
              },
            )(true);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          notify(t('quick-trade.not-found'));
          console.error(e);
        });
    } else {
      notify(t('quick-trade.not-found'));
      setIsLoading(false);
      console.log('Clipboard is empty');
    }
  };

  if (!quickTradeSettingsLoaded || !enabledSettings.length) return null;

  return (
    <>
      <div id="quick-trade-actions">
        <div className="d-flex justify-content-end align-items-center">
          <button className="btn btn-primary px-3" onClick={readClipboard}>
            <IconWithLoading isLoading={isLoading} className="" />
            {!isLoading ? t('quick-trade.title') : null}
          </button>

          <button
            className="btn btn-primary px-3"
            onClick={() => navigate(PageRoutes.QUICK_TRADE)}
          >
            <img src={filters} alt="filters" />
          </button>
        </div>
      </div>

      <QuickTradeConfirmation
        settings={networkSettings}
        item={token}
        onCancel={() => {
          setToken(null);
          setIsLoading(false);
        }}
        onProceed={() => {
          if (token && networkSettings) {
            handleOrder(token, networkSettings);
            setToken(null);
          }
        }}
      />
    </>
  );
};

export default observer(QuickTradeButtons);
