import BigNumber from 'bignumber.js';
import { isNaN } from '@helpers/bignumber';

export const isUsdInputValid = (value: number | string | null) => {
  return !isNaN(value) || [null, '', NaN].includes(value);
};

export const isPercentInputValid = (value: number | string | null) => {
  return !isNaN(value) || [null, '-', NaN].includes(value);
};

export const percentDiff = (
  value: string | number | BigNumber,
  percent: string | number | BigNumber,
) => {
  const bn = new BigNumber(value);
  const bnPercent = new BigNumber(percent).div(100);
  return bn.plus(bn.multipliedBy(bnPercent))
};

export const changeInPercents = ([value, divider]: (number | string)[]) => {
  const valueBn = new BigNumber(value);
  const divBn = new BigNumber(divider);

  return valueBn.minus(divBn).div(divBn).multipliedBy(100)
};

export function adjustArray(
  arr: (number | string | null)[],
  [newFirst, newLast]: [number | undefined, number | undefined],
  decimalPlaces?: number,
) {
  const length = arr.length;

  const bigArr = arr.map((num) => new BigNumber(num ?? 0));

  if (newFirst !== undefined) {
    const newFirstBig = new BigNumber(newFirst);
    const oldFirstBig = bigArr[0];
    const lastBig = bigArr[length - 1];

    return bigArr
      .map((value, index) => {
        if (index === 0) return newFirstBig;
        if (index === length - 1) return lastBig;

        const relativePosition = value
          .minus(oldFirstBig)
          .dividedBy(lastBig.minus(oldFirstBig));
        return newFirstBig.plus(
          relativePosition.multipliedBy(lastBig.minus(newFirstBig)),
        );
      })
      .map((bn) =>
        (decimalPlaces ? bn.decimalPlaces(decimalPlaces) : bn).valueOf(),
      );
  }

  if (newLast !== undefined) {
    const firstBig = bigArr[0];
    const oldLastBig = bigArr[length - 1];
    const newLastBig = new BigNumber(newLast);

    return bigArr
      .map((value, index) => {
        if (index === 0) return firstBig;
        if (index === length - 1) return newLastBig;

        const relativePosition = value
          .minus(firstBig)
          .dividedBy(oldLastBig.minus(firstBig));
        return firstBig.plus(
          relativePosition.multipliedBy(newLastBig.minus(firstBig)),
        );
      })
      .map((bn) =>
        (decimalPlaces ? bn.decimalPlaces(decimalPlaces) : bn).valueOf(),
      );
  }

  return arr;
}

export const replaceInvalidValues = (
  arr: (number | null)[],
  invalidIndices: (number | null)[],
  decimalPlaces?: number,
) => {
  const isValid = (value: number | null) => {
    return value !== null && !isNaN(value);
  };

  invalidIndices.forEach((index) => {
    //@ts-ignore
    arr[index] = null;
  });
  const result = arr.map((value, index) => {
    if (isValid(value)) return value;

    const prevValidIndex = arr
      .slice(0, index)
      .reverse()
      .findIndex((v) => isValid(v));
    const nextValidIndex = arr.slice(index + 1).findIndex((v) => isValid(v));

    if (prevValidIndex === -1 || nextValidIndex === -1) return null;

    const prevIndex = index - prevValidIndex - 1;
    const nextIndex = index + nextValidIndex + 1;

    const prevValue = arr[prevIndex];
    const nextValue = arr[nextIndex];

    const totalSteps = nextIndex - prevIndex;
    const step = (nextValue! - prevValue!) / totalSteps;

    const bn = new BigNumber(prevValue! + step * (index - prevIndex));
    const resBn = decimalPlaces ? bn.decimalPlaces(decimalPlaces) : bn;

    return resBn.toNumber();
  });

  return result;
};

export const generateTriggerValues = (
  currentValue: string | number | BigNumber | null,
  autoLevelStep: string | number | BigNumber | null,
  levelPercents: string | number | BigNumber | null,
) => {
  if (!(currentValue && autoLevelStep && levelPercents)) return [];

  const bnAutoLevelStep = new BigNumber(autoLevelStep);
  const bnStartLevel = new BigNumber(levelPercents);

  return Array.from({ length: 100 }, (_, index) => {
    const level = bnStartLevel.plus(bnAutoLevelStep.times(index));

    const triggerValue = percentDiff(currentValue, level);

    return { triggerValue: triggerValue.valueOf() };
  }).filter(({ triggerValue }) => +triggerValue > 0);
};
