import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en/en.json';
import ru from './locales/ru/ru.json';
import cn from './locales/cn/cn.json';
import pt from './locales/pt/pt.json';
import ko from './locales/ko/ko.json';
import { Language } from './types/enums';

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
  cn: {
    translation: cn,
  },
  ko: {
    translation: ko,
  },
  pt: {
    translation: pt,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,

    fallbackLng: Language.EN,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
