import { createContext } from 'react';
import { SocketStore } from '@stores/socket-store';
import { AuthStore } from '@stores/auth-store';
import { AccountStore } from '@stores/account-store';
import { SettingsStore } from '@stores/settings-store';
import { SwapStore } from '@stores/swap-store';
import { OrdersStore } from '@stores/orders-store';
import { TokenStore } from '@stores/token-store';
import { TokenTrade } from '@stores/token-trade';
import { QuickTradeStore } from '@stores/quick-trade-store';
import { WhaleSignalsStore } from '@stores/whale-signals-store';

const authStore = new AuthStore();
const accountStore = new AccountStore(authStore);
const socketStore = new SocketStore(authStore, accountStore);
const settingsStore = new SettingsStore(authStore);
const ordersStore = new OrdersStore(accountStore);
const tokenStore = new TokenStore(accountStore, ordersStore);
const tokenTrade = new TokenTrade(tokenStore, settingsStore);
const quickTradeStore = new QuickTradeStore(accountStore);
const whaleSignalsStore = new WhaleSignalsStore(socketStore, ordersStore);

export const storesContext = createContext({
  authStore,
  accountStore,
  socketStore,
  settingsStore,
  swapStore: new SwapStore(),
  ordersStore,
  tokenStore,
  tokenTrade,
  quickTradeStore,
  whaleSignalsStore,
});
