import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@hooks/useStores';
import { ResponseData, StatusResponse } from '@helpers/api';
import useNotification from '@hooks/useNotification';
import { useTranslation } from 'react-i18next';

export default function useResponseHandler() {
  const { accountStore } = useStores();
  const navigate = useNavigate();
  const notify = useNotification();
  const { t } = useTranslation();

  return useCallback(
    (
      response?: StatusResponse<any> | ResponseData<any> | string,
      isError?: boolean,
    ) => {
      const toastOptions: { duration: number; id?: string } = {
        duration: 5000,
      };
      const redirect = false;
      let isSessionExpired = false;
      let message: string = '';

      // console.log('useResponseHandler', {response});
      if (response && typeof response !== 'string') {
        //@ts-ignore
        if (response?.message || response?.data?.message) {
          //@ts-ignore
          message = t(response.message || response.data?.message);
        } else if (response.data?.errorCode && response.data?.errorCode.length) {
          message = t(response?.data.errorCode, {
            ...(response.data?.errorArgs ?? {}),
          } as string);
        } else if (response.data?.error || response.data?.message) {
          isError = true;
          message = response?.data.error || response.data?.message;
        }
        if (response.status === 403) {
          isError = true;
          message = message || t('alert.session-expired');
          isSessionExpired = true;
          accountStore.logout();
        }
        if (!isError && response.status !== 200) {
          isError = true;
        }
      } else if (typeof response === 'string') {
        isError = isError || response.toLocaleLowerCase().indexOf('error') > -1;
        message = t(response);
      }

      if (isSessionExpired) {
        toastOptions.id = 'session-expired';
      }

      if (isError && !message.length) {
        message = t('alert.error-occurred');
      } else {
        const splitted = message.split(']');
        message = splitted.length > 1 ? splitted[1] : message;
      }

      if (isError) {
        if (!redirect) {
          notify(message, { ...toastOptions, type: 'danger' });
        } else {
          navigate('/error', { state: { messages: message } });
        }
      } else {
        notify(message, toastOptions);
      }
    },
    [accountStore, navigate],
  );
}
