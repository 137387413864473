import React, { useCallback, useEffect, useMemo, useState } from 'react';
import NetworkSelection from '@components/NetworkSelection';
import arrow from '@assets/icons/arrow-down-dark.svg';
import qrScan from '@assets/icons/qr-scan.svg';
import myWallets from '@assets/icons/my-wallets.svg';
import gasIcon from '@assets/icons/gas.svg';
import sendArrow from '@assets/images/send-arrow.svg';
import FormattedNumber from '@components/common/FormattedNumber';
import BigNumber from 'bignumber.js';
import { getEllipsisTxt } from '@helpers/formatters';
import Preloader from '@components/common/Preloader';
import { useNavigate } from 'react-router-dom';
import { ChainId, ethRegex, PageRoutes, suiWalletRegex, tonRegex, tronRegex } from '../../constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { chainLogo, chainName, chainToken } from '@helpers/chains';
import TokenLogo from '@components/common/TokenLogo';
import { SendFormParams } from '@pages/Send/index';
import { roundDown } from '@helpers/numbers';
import { useScanQrPopup } from '@vkruglikov/react-telegram-web-app';
import { ApiCalculateGas } from '../../types';
import useResponseHandler from '@hooks/useResponseHandler';
import { greaterThan } from '@helpers/bignumber';
import useDebounce from '@hooks/useDebounce';
import { Blockchain } from '../../types/enums';
import { TransferEstimateGasResponse } from '../../types/trading/transfer-estimate-gas.response';
import WalletSelection from '@components/WalletSelection';
import UserAvatar from '@components/common/UserAvatar';
import NonEvmChainLabel from '@components/NonEvmChainLabel';
import { Trans, useTranslation } from 'react-i18next';
import cn from 'classnames';
import useInterval from '@hooks/useInterval';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';

const SendForm = observer((props: SendFormParams) => {
  const { t } = useTranslation();
  const {
    network,
    setNetwork,
    asset,
    nativeToken,
    address,
    setAddress,
    amount,
    numericAmount,
    setAmount,
  } = props;
  const { accountStore, swapStore } = useStores();
  const [show, close] = useScanQrPopup();
  const {
    assetsLoaded,
    address: accountAddress,
    currentWallet,
    wallets,
  } = accountStore;
  const { isEvm, isTon, isTron, isSui, isSolana } = accountStore;
  const navigate = useNavigate();
  const [w] = useAmplitude();
  const [gas, setGas] = useState<TransferEstimateGasResponse | null>(null);
  const [memo, setMemo] = useState<string>();
  const [isGasLoaded, setIsGasLoaded] = useState<boolean>(true);
  const handleResponse = useResponseHandler();
  const debounceAmount = useDebounce(numericAmount, 1000);
  const debounceNetwork = useDebounce(network, 100);
  const debounceWallet = useDebounce(currentWallet?.address, 100);
  const [canGetGas, setCanGetGas] = useState<boolean>(true);
  const [lastGasUpdate, setLastGasUpdate] = useState<number>(0);
  const [isMax, setIsMax] = useState<boolean>(false);
  const [ticker, setTicker] = useState<number>(0);

  useEffect(() => {
    if (isEvm && [ChainId.SOLANA, ChainId.TON].includes(network)) {
      setNetwork(ChainId.ETHER);
    }
  }, [isEvm, network]);

  const foundOwnWallet = useMemo(
    () =>
      wallets.find(({ address: walletAddress }) => walletAddress === address)
        ?.name,
    [address],
  );

  const isAddressValid = useCallback(
    (value: string) => {
      if (isEvm) {
        return ethRegex.test(value);
      }
      if (isTon) {
        return tonRegex.test(value);
      }
      if (isTron) {
        return tronRegex.test(value);
      }
      if (isSui) {
        console.log('is sui');
        return suiWalletRegex.test(value);
      }
      return value.length > 31 && value.length < 45;
    },
    [isEvm, isTon, isTron, isSui],
  );

  const filledAddress = useMemo(() => {
    const addr = /^0x/.test(address) ? address.replace(/^0x/, '') : address;
    const arr = new Array(8).fill('0');
    if (addr.length < 8) {
      arr.splice(0, addr.length, ...addr.split(''));
    } else {
      arr.splice(0, 4, ...addr.split('').slice(0, 4));
      arr.splice(4, 4, ...addr.split('').slice(-4));
    }
    return arr.join('');
  }, [address]);

  useEffect(() => {
    if (
      !currentWallet ||
      !isEvm ||
      !canGetGas ||
      !nativeToken ||
      new BigNumber(nativeToken.quantity).eq(0)
    ) {
      return;
    }

    if (lastGasUpdate && Date.now() - lastGasUpdate < 10 * 1000) {
      return;
    }

    if (
      (address ? isAddressValid(address) : true) &&
      (debounceAmount
        ? debounceAmount && !new BigNumber(debounceAmount).eq(0)
        : !new BigNumber(asset?.quantity || 0).eq(0))
    ) {
      setGas(null);
      const sendData: ApiCalculateGas = {
        walletId: currentWallet.id,
        blockchain: network as unknown as Blockchain,
        amount: debounceAmount || asset?.quantity || 0,
        toAddress: address || currentWallet.address,
      };
      if (
        asset &&
        nativeToken &&
        nativeToken.contractAddress !== asset.contractAddress
      ) {
        sendData.tokenAddress = asset.contractAddress;
      }
      setIsGasLoaded(false);
      accountStore
        .calculateGas(sendData)
        .then((response) => {
          setLastGasUpdate(Date.now());
          setIsGasLoaded(true);
          if (!response?.nativeTokenUsdPrice) {
            handleResponse(response);
            setGas(null);
            return;
          }
          setGas(response);
        })
        .catch((e) => {
          setIsGasLoaded(true);
          handleResponse(e);
        });
    } else {
      setGas(null);
    }
  }, [
    currentWallet,
    address,
    accountAddress,
    debounceAmount,
    asset,
    network,
    isAddressValid,
    accountStore,
    handleResponse,
    nativeToken,
    isEvm,
    asset,
    canGetGas,
    nativeToken,
    lastGasUpdate,
  ]);

  useEffect(() => {
    if (
      network !== debounceNetwork ||
      currentWallet?.address !== debounceWallet
    ) {
      setLastGasUpdate(0);
      setCanGetGas(false);
      setGas(null);
    } else {
      setCanGetGas(true);
    }
  }, [network, debounceNetwork, currentWallet, debounceWallet]);

  const amountValue = useMemo(() => {
    let value = roundDown(
      numericAmount,
      asset?.decimals < 4 ? asset.decimals : 4,
    );
    const _amount = new BigNumber(numericAmount).toNumber();

    if (!value && _amount > value) {
      value = _amount;
    }

    const stringValue = new BigNumber(value).toFixed();

    const integer = parseInt(stringValue);
    const digits = stringValue.split('.')[1];
    const result = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '');
    return `${result}${digits ? '.' + digits.substring(0, 4) : ''}`;
  }, [numericAmount, asset]);

  const onScan = (result: string) => {
    let value = result;
    if (result.split(':').length > 1) {
      value = result.split(':')[1];
    }
    if (isAddressValid(value)) {
      setAddress(value);
      close();
    }
  };

  const feeAmount = useMemo(() => {
    if (!isEvm) {
      if (isTron) return '30';
      return isTon ? '0.01' : '0.000005';
    }
    return gas
      ? new BigNumber(gas.gas || 0)
          .times(gas.weiGasPrice || 0)
          .div(10 ** (nativeToken?.decimals || 18))
          .toString()
      : null;
  }, [gas, nativeToken, isEvm, isTon]);

  const isInvalidAmount = useMemo(() => {
    return (
      !!numericAmount &&
      !!asset?.quantity &&
      greaterThan(numericAmount, asset.quantity)
    );
  }, [numericAmount, asset]);

  const isEnoughAmount = useMemo(() => {
    if (!feeAmount) {
      return 0;
    }
    if (!nativeToken) {
      return 0;
    }
    if (!asset) {
      return 0;
    }
    const reqAmount =
      asset.assetId === nativeToken.assetId
        ? new BigNumber(numericAmount || 0).plus(feeAmount || 0).toString()
        : feeAmount || 0;
    return new BigNumber(nativeToken.quantity).minus(reqAmount).toNumber();
  }, [asset, numericAmount, nativeToken, feeAmount]);

  const isNativeTokenEnough = useMemo(() => {
    return isEnoughAmount >= 0;
  }, [isEnoughAmount]);

  const handleSubmit = () => {
    if (
      (isEvm && !gas) ||
      !numericAmount ||
      !isAddressValid ||
      !isNativeTokenEnough ||
      isInvalidAmount
    ) {
      return;
    }
    navigate(PageRoutes.SEND_CONFIRM, {
      state: {
        amount: numericAmount,
        address,
        network,
        assetId: asset.assetId,
        gas,
        memo,
      },
    });
  };

  const maxAmount = useMemo(() => {
    if (asset && asset?.contractAddress !== nativeToken?.contractAddress && asset?.assetSymbol !== nativeToken?.assetSymbol) {
      return asset.quantity;
    }

    const fee = new BigNumber(feeAmount || 0).multipliedBy(1.015);
    return new BigNumber(asset?.quantity || 0).minus(fee).toFixed(asset?.decimals || 18, BigNumber.ROUND_DOWN);
  }, [feeAmount, asset, nativeToken]);

  const handleSetMax = () => {
    setIsMax(true);
    setAmount(maxAmount);
  };

  const handleSetAmount = (v: string) => {
    let value = new BigNumber(v);
    let isCut = false;
    if (asset?.decimals &&  (value.decimalPlaces() || 0) > asset?.decimals) {
      value = value.decimalPlaces(asset?.decimals, BigNumber.ROUND_DOWN);
      isCut = true;
    }
    setIsMax(v === maxAmount);
    setAmount(isCut ? value.toString() : v);
  };

  useEffect(() => {
    if (
      isMax &&
      gas &&
      !isNativeTokenEnough &&
      lastGasUpdate &&
      lastGasUpdate - Date.now() < 0.5 * 1000
    ) {
      handleSetMax();
    }
  }, [lastGasUpdate, isMax]);

  useInterval(
    () => {
      if (lastGasUpdate && Date.now() - lastGasUpdate > 10 * 1000) {
        setLastGasUpdate(0);
      }
    },
    isEvm ? 1000 : null,
  );

  if (!asset || !assetsLoaded) {
    return (
      <div className="py-3">
        <div className="d-flex justify-content-between align-items-center">
          <WalletSelection className="btn btn-transparent p-0 wd-40 ht-40">
            <UserAvatar string={currentWallet?.address || ''} />
          </WalletSelection>
          <div className="ms-2 me-auto d-flex flex-column justify-content-center">
            <div className="tx-17 lh-3">
              {currentWallet?.name ||
                getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
            </div>
            {isEvm && (
              <NetworkSelection
                network={network}
                onChange={setNetwork}
                disabledNetworks={[ChainId.SOLANA, ChainId.TON, ChainId.TRON]}
              >
                <div className="d-flex align-items-center tx-12">
                  <img
                    src={chainLogo(network)}
                    alt="chain"
                    width={16}
                    className={`me-1 ${network === ChainId.ALL ? 'd-none' : ''}`}
                  />
                  <span className="me-1">{chainName(network)}</span>
                  <img src={arrow} alt="Choose network" width={16} />
                </div>
              </NetworkSelection>
            )}
            {!isEvm && (
              <NonEvmChainLabel chainId={network} />
            )}
          </div>
        </div>

        <div className="tx-22 tx-semibold my-4">{t('common.send')}</div>

        {assetsLoaded && !asset && (
          <div className="card bg-transparent border border-solid border-warning tx-warning d-block mt-4">
            <div className="tx-20 tx-semibold">
              {t('sending.no-tokens-to-send')}
            </div>
            <div className="tx-white">{t('sending.receive-tokens-first')}</div>
          </div>
        )}

        {!assetsLoaded && (
          <Preloader
            className="d-flex flex-column"
            iconSize={65}
            textClass="mt-3"
            text={t('sending.loading-assets')}
            inline
          />
        )}

        <button
          className="btn btn-primary-10 wd-100p mt-4"
          onClick={() => navigate(PageRoutes.RECEIVE)}
        >
          {t('common.receive')}
        </button>
      </div>
    );
  }

  return (
    <div className="py-3">
      <div className="d-flex justify-content-between align-items-center">
        <WalletSelection className="btn btn-transparent p-0 wd-40 ht-40">
          <UserAvatar string={currentWallet?.address || ''} />
        </WalletSelection>
        <div className="ms-2 me-auto d-flex flex-column justify-content-center">
          <div className="tx-17 lh-3">
            {currentWallet?.name ||
              getEllipsisTxt(currentWallet?.address || '', 4, '0x')}
          </div>
          {isEvm && (
            <NetworkSelection
              network={network}
              onChange={setNetwork}
              disabledNetworks={[ChainId.SOLANA, ChainId.TON, ChainId.TRON]}
            >
              <div className="d-flex align-items-center tx-12">
                <img
                  src={chainLogo(network)}
                  alt="chain"
                  width={16}
                  className={`me-1 ${network === ChainId.ALL ? 'd-none' : ''}`}
                />
                <span className="me-1">{chainName(network)}</span>
                <img src={arrow} alt="Choose network" width={16} />
              </div>
            </NetworkSelection>
          )}
          {!isEvm && (
            <NonEvmChainLabel chainId={network} />
          )}
        </div>
      </div>

      <div className="tx-22 tx-semibold my-4">{t('common.send')}</div>

      <div className="card p-0">
        <div className="p-3">
          <div className="d-flex justify-content-end align-items-center">
            <div className="tx-17 tx-semibold ms-0 me-auto">
              {t('common.token')}
            </div>
            {!!numericAmount && (
              <button
                className="btn btn-outline-gray-light tx-13 tx-semibold px-3 me-2"
                onClick={() => setAmount('')}
              >
                {t('common.clear')}
              </button>
            )}
            <button
              className="btn btn-outline-gray-light tx-13 tx-semibold px-3"
              onClick={w(handleSetMax, AmplitudeEvent.SEND_MAX_BUTTON_CLICKED)}
            >
              {t('common.max')}
            </button>
          </div>

          <div className="d-flex justify-content-start align-items-center mt-3">
            <div
              className="d-flex justify-content-start align-items-center"
              onClick={() => swapStore.setIsTokenSelection(true)}
            >
              <TokenLogo
                logo={asset.imageUrl}
                address={asset.assetId}
                chain={asset.assetChain}
                name={asset.assetName}
                size={35}
                hideChain
              />
              <div className="tx-17 ms-3">{asset.assetSymbol}</div>
              <img src={arrow} alt="Arrow" className="ms-1" />
            </div>

            <input
              // type="number"
              type="text"
              className={`form-control bg-transparent border-0 tx-20  tx-right py-0 px-0 appearance-none ${isInvalidAmount ? 'tx-danger-f' : 'tx-muted'}`}
              value={numericAmount === asset.quantity ? amountValue : amount}
              placeholder="0"
              // pattern="[0-9]+(\\.[0-9][0-9]?)?"
              // pattern="[0-9]+([.,][0-9][0-9]?)?"
              inputMode="decimal"
              // lang="en-150"
              // step="0.01"
              onChange={(e) => handleSetAmount(e.target.value)}
              onBlur={() => window.scrollTo(0, 0)}
            />
          </div>

          <div
            className={`d-flex justify-content-start align-items-center tx-13 mt-2`}
          >
            <FormattedNumber
              suffix={`${t('common.bal')}: `}
              decimals={4}
              floor={true}
              value={asset.quantity}
              className={`${asset.quantity === '0' ? 'tx-muted' : ''}`}
            />
            <FormattedNumber
              suffix="("
              value={asset.value}
              postfix="$)"
              className={`tx-muted ms-1 ${asset.quantity === '0' ? 'd-none' : ''}`}
            />
            <FormattedNumber
              suffix="$"
              value={new BigNumber(numericAmount || 0)
                .times(asset.price)
                .toNumber()}
              className={`ms-auto me-0 ${!numericAmount ? 'tx-muted' : ''} ${isInvalidAmount ? 'tx-danger-f' : ''}`}
            />
          </div>
        </div>

        <div className="swap-separator">
          <img src={sendArrow} alt="Swap" className="wd-40 ht-40" />
        </div>

        <div className="p-3">
          <div className="d-flex justify-content-between">
            <div className="tx-17 tx-semibold d-flex">
              <span>{t('common.receiver')}</span>
            </div>
            <div className="d-flex gap-3">
              <WalletSelection
                onChange={(e) =>
                  w(setAddress, AmplitudeEvent.SEND_WALLET_SELECTED, {
                    wallet: wallets.find((w) => w.id === e),
                  })(wallets.find((w) => w.id === e)!.address)
                }
                hideCurrent
                filterTypes={[currentWallet!.type]}
              >
                <img src={myWallets} alt="My Wallets" className="wd-25 ht-25" />
              </WalletSelection>
              <img
                src={qrScan}
                alt="QR Scan"
                className="wd-25 ht-25"
                onClick={() =>
                  w(show, AmplitudeEvent.SEND_QR_CODE_CLICKED)(
                    { text: t('sending.scan-address-qr-code') },
                    onScan,
                  )
                }
              />
            </div>
          </div>

          <input
            type="text"
            className={`form-control bg-transparent border-0 tx-22 py-0 px-0 appearance-none mt-3 ${!address ? 'tx-muted' : isAddressValid(address) ? 'tx-white' : 'tx-danger'}`}
            value={address}
            placeholder={t('sending.address-domain-identity')}
            onChange={(e) => setAddress(e.target.value)}
            onBlur={() => window.scrollTo(0, 0)}
          />

          <div
            className={`d-flex align-items-center tx-13 mt-2 ${!address ? 'tx-muted' : 'tx-white'}`}
          >
            {getEllipsisTxt(filledAddress, 4, '0x')}
            {foundOwnWallet && (
              <span className="tx-muted ms-1">({foundOwnWallet})</span>
            )}
          </div>
        </div>
      </div>
      {isTon && (
        <>
          <input
            type="text"
            className="form-control border-0 mt-3"
            placeholder={t('wallet.tag')}
            onChange={(e) => setMemo(e.currentTarget.value)}
            onBlur={() => window.scrollTo(0, 0)}
          />
          <div className="d-block tx-13 tx-muted mt-2 ms-1">
            {t('order.ton-transaction-time')}:{' '}
            <span className="text-warning">
              {t('order.ton-transaction-duration')}
            </span>
            .
          </div>
        </>
      )}
      {isAddressValid(address) && (!isGasLoaded || gas) && (
        <div
          className={cn([
            'card',
            'mt-4',
            'tx-13',
            'wd-100p',
            'd-flex',
            'flex-column',
          ])}
        >
          <div className="px-0 py-0 d-flex flex-row justify-content-between align-items-center wd-100p">
            <div className={`ms-0 me-auto ${!gas ? 'blurred-row' : ''}`}>
              <FormattedNumber
                value={feeAmount}
                decimals={6}
                postfix={chainToken(network)}
                subZeros
              />
              <FormattedNumber
                value={
                  feeAmount && gas
                    ? new BigNumber(feeAmount)
                        .times(gas.nativeTokenUsdPrice || 0)
                        .toNumber()
                    : null
                }
                suffix="($"
                postfix=")"
                className="tx-muted ms-2"
              />
            </div>
            <img src={gasIcon} alt="Gas" className="ms-auto me-2" />
            {!gas && (
              <Preloader text="" iconSize={25} iconClass="d-block" inline />
            )}
            {gas && <FormattedNumber value={gas.gas || 0} />}
          </div>
        </div>
      )}

      {!isNativeTokenEnough && (
        <div className="card bg-transparent border border-solid border-danger tx-muted d-block mt-4">
          <div>
            {t('sending.not-enough-tokens-for-fee', {
              chainToken: chainToken(network),
            })}
          </div>
          <Trans
            i18nKey="sending.more-gas-needed-for-swap"
            t={t}
            components={{
              amount: (
                <FormattedNumber
                  value={isEnoughAmount * -1}
                  decimals={6}
                  postfix={chainToken(network)}
                  className="tx-white mx-2"
                />
              ),
            }}
          />
        </div>
      )}

      <button
        className={cn([
          'btn',
          'btn-primary-10',
          'wd-100p',
          isTon ? 'mt-2' : 'mt-4',
        ])}
        disabled={
          (isEvm && !gas) ||
          !numericAmount ||
          !isAddressValid(address) ||
          !isNativeTokenEnough ||
          isInvalidAmount
        }
        onClick={w(handleSubmit, AmplitudeEvent.SEND_CONTINUE_CLICKED)}
      >
        {t('common.continue')}
      </button>
    </div>
  );
});

export default SendForm;
