import React from "react";
import {formatNumber} from "@helpers/numbers";
import {isNumber} from "@helpers/bignumber";
import { useTranslation } from 'react-i18next';

interface LabelValueCardProps {
  label: string;
  value?: number | string;
  unit?: string;
  className?: string;
  valueClassName?: string;
  labelClassName?: string;
  children?: React.ReactNode;
  revert?: boolean;
}

const LabelValueCard = (props: LabelValueCardProps) => {
  const {
    label,
    value,
    unit,
    revert = false,
    className = 'bg-semi-transparent-10 p-2',
    labelClassName = 'tx-muted',
    valueClassName = '',
    children
  } = props
  const { t } = useTranslation();
  const formattedValue = value !== undefined && !children
    ? isNumber(value) ? formatNumber(value as number, unit) : value
    : children;

  const labelElement = <div className={labelClassName}>{label}</div>;

  return (
    <div
      className={`wd-100p card d-flex flex-column align-items-center justify-content-center tx-center tx-12 ${className}`}
      style={{ minHeight: '52px' }}>
      {!revert && labelElement}
      <div className={valueClassName}>{!formattedValue && value === undefined ? t('common.na') : formattedValue}</div>
      {revert && labelElement}
    </div>
  );
};

export default LabelValueCard;
