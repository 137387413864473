import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import Lottie from 'lottie-react';
import siriIcon from '@assets/lottie/siri-icon.json';
import BadgeIconNew from '@components/common/BadgeIconNew';
import question from '@assets/icons/question-teal.svg';
import portal from '@assets/images/portal.svg';
import support from '@assets/images/support.svg';
import xCom from '@assets/images/x-com.svg';
import Badge from '@components/common/Badge';

const HelpModal = () => {
  const { t } = useTranslation();
  const [w] = useAmplitude();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="pt-4">
          <BadgeIconNew>
            <img src={question} alt="question" />
          </BadgeIconNew>

          <div className="tx-center tx-18 tx-semibold mt-3">
            {t('wallet.have-a-problem')}
          </div>
          <div className="tx-center tx-14 tx-muted mt-1 px-3 mb-4">
            {t('wallet.solve-it')}
          </div>

          <a
            className="bg-semi-transparent-10 rounded px-3 py-3 text-decoration-none d-flex align-items-center gap-2"
            href="https://t.me/AlphaDexVisionbot"
            target="_blank"
            rel="noreferrer"
            onClick={w(() => null, AmplitudeEvent.AI_ASSISTANT_CLICKED)}
          >
            <div className="wd-45 ht-45">
              <Lottie
                animationData={siriIcon}
                loop={true}
                className="ai-logo"
              />
            </div>
            <div>
              <div className="tx-14 tx-white">{t('wallet.ai-assistant')}</div>
              <div className="tx-12 tx-muted">
                {t('wallet.smart-ai-helper')}
              </div>
            </div>
          </a>

          <a
            className="mt-2 bg-semi-transparent-10 rounded px-3 py-3 text-decoration-none d-flex align-items-center gap-2"
            href={t('links.portal')}
            target="_blank"
            rel="noreferrer"
          >
            <img src={portal} alt="portal" width={45} />
            <div>
              <div className="tx-14 tx-white">Alpha DEX CIS</div>
              <div className="tx-12 tx-muted">{t('wallet.alpha-dex-cis')}</div>
            </div>
          </a>

          <a
            className="mt-2 bg-semi-transparent-10 rounded px-3 py-3 text-decoration-none d-flex align-items-center gap-2"
            href={t('links.alpha-dex-x')}
            target="_blank"
            rel="noreferrer"
          >
            <div className="d-flex justify-content-center bg-gray-900 rounded-circle wd-45 ht-45">
              <img src={xCom} alt="portal" width={25} />
            </div>
            <div>
              <div className="tx-14 tx-white">Alpha Dex (@alpha_dex_trade)</div>
              <div className="tx-12 tx-muted">{t('wallet.alpha-dex-x')}</div>
            </div>
          </a>

          <div className="text-over-line">
            <span className="tx-14 tx-muted">
              {t('wallet.contact-support')}
            </span>
          </div>

          <a
            className="mt-3 bg-semi-transparent-10 rounded px-3 py-3 text-decoration-none d-flex align-items-center gap-2"
            href="https://t.me/alpha_dex_support"
            target="_blank"
            rel="noreferrer"
            onClick={w(() => null, AmplitudeEvent.SUPPORT_CLICKED)}
          >
            <img src={support} alt="support" width={45} />
            <div className="tx-14 tx-white">Alpha DEX Support</div>
            <Badge
              className="ms-auto"
              label={t('common.online')}
              variant="success"
            />
          </a>
        </Modal.Body>
      </Modal>

      <button
        className="ps-3 pe-2 bg-transparent border-2 border-solid border-semi-transparent-10 ht-40 tx-white text-decoration-none rounded-pill overflow-hidden cur-pointer tx-14 tx-semibold d-flex align-items-center justify-content-center"
        onClick={() => setShow(true)}
      >
        <span>{t('common.help')}</span>
        <div className="wd-30 ht-30 ms-2">
          <Lottie animationData={siriIcon} loop={true} className="ai-logo" />
        </div>
      </button>
    </>
  );
};

export default HelpModal;
