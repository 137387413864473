import {
  action,
  autorun,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { AuthStore } from './auth-store';
import { createSocket } from '@helpers/socket';
import { Socket } from 'socket.io-client';
import { AccountStore } from './account-store';

enum Events {
  PING = 'PING',
  PONG = 'PONG',
}

export class SocketStore {
  @observable
  private _socket: Socket;

  @observable
  private _isOnline: boolean;

  @observable
  private _intervalId: NodeJS.Timer;

  constructor(authStore: AuthStore, accountStore: AccountStore) {
    makeAutoObservable(this);

    autorun(() => {
      if (!this._socket && accountStore.checkDone && authStore.accessToken) {
        runInAction(
          () =>
            (this._socket = createSocket({
              authorization: authStore.accessToken!,
            })),
        );
        this.subscribePong();
        this.ping();
      }
    });
  }

  get socket() {
    return this._socket;
  }

  get isOnline() {
    return this._isOnline;
  }

  get isConnected() {
    return this._socket.connected;
  }

  @action
  setIsOnline(s: boolean) {
    this._isOnline = s;
  }

  @action
  ping() {
    clearInterval(this._intervalId);
    this.socket.emit(Events.PING);
    this._intervalId = setInterval(() => {
      this.socket.emit(Events.PING);
    }, 10000);
  }

  @action
  subscribePong() {
    this.socket.on(Events.PONG, (data) => this.setIsOnline(!!data));
  }
}
