import React from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';

type Props = {
  value: boolean;
  onClick: (value: boolean) => void;
};

const AutomaticLevelToggle = ({ value, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex gap-2 align-items-center mg-t-12">
      <Toggle
        icons={false}
        className="styled-toggle"
        checked={value}
        onChange={({ target: { checked: v } }) => onClick(v)}
      />
      <span className="tx-13">{t('order.alpha.automatically')}</span>
    </div>
  );
};

export default AutomaticLevelToggle;
