import React from 'react';
import { anomalyHourDiff } from '@helpers/anomalyHourDifference';
import cn from 'classnames';
import { HistoryData as THistoryData } from 'types/whale-signals/whale-signals.request';

type Props = {
  data: THistoryData<number>[];
  className?: string;
  plusClassName?: string;
  minusClassName?: string;
};

const HistoryData = (props: Props) => {
  const {
    data,
    className = 'tx-13',
    plusClassName = 'tx-success',
    minusClassName = 'tx-danger',
  } = props;

  return (
    <>
      <span className={className}>{data[0].value}</span>
      <span
        className={cn([
          className,
          +anomalyHourDiff(data) === 0 && 'd-none',
          +anomalyHourDiff(data) > 0 ? plusClassName : minusClassName,
        ])}
      >
        {+anomalyHourDiff(data) > 0 ? '+' : '-'}
        {Math.abs(+anomalyHourDiff(data))}
      </span>
    </>
  );
};

export default HistoryData;
