import React from 'react';
import { percentDiff } from '@helpers/calculations';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';

type Props = {
  value: string | number;
  buttons: number[];
  onClick: (value: string | number) => void;
  disabled?: boolean;
};

const PercentButtons = ({ value, buttons, onClick, disabled }: Props) => {
  const getLabel = (percent: number) => {
    const bn = new BigNumber(percent);
    return bn.isLessThan(100)
      ? `${bn.decimalPlaces(1).valueOf()}%`
      : `x${bn.div(100).plus(1).decimalPlaces(1).valueOf()}`;
  };

  return (
    <div className="d-flex gap-2">
      {buttons.map((percent) => (
        <div
          key={percent}
          className={classNames(
            'd-flex justify-content-center align-items-center btn btn-semi-10 p-2 wd-100p tx-13 ht-32',
            disabled && 'disabled',
          )}
          onClick={() => onClick(percentDiff(value, percent).valueOf())}
        >
          {getLabel(percent)}
        </div>
      ))}
    </div>
  );
};

export default PercentButtons;
