import { Locale, formatDistanceToNow } from 'date-fns';
import { ru, enUS, ko, ptBR, vi, zhCN } from 'date-fns/locale';

const locales = {
  'en-EN': enUS,
  'ru-RU': ru,
  'ko-KR': ko,
  'cn-CN': zhCN,
  'pt-BR': ptBR,
  'vi-VN': vi,
};

export const getDateFnsLocale = (code: string) => {
  const [lc] = Object.keys(locales).filter((c) => c.includes(code));
  return locales[lc as keyof typeof locales] ?? enUS;
};

export default function timeAgo(
  date: number | string | Date,
  addSuffix = false,
  locale: Locale,
): string {
  return (
    formatDistanceToNow(new Date(date), { addSuffix, locale })
      // EN rules
      .replace('about ', '')
      .replace('less than a ', '')
      // RU rules
      .replace('меньше минуты', '1 минута')
      .replace('около', '')
      .replace('1 часа', '1 час')
      .replace('2 часов', '2 часа')
      .replace('3 часов', '3 часа')
      .replace('4 часов', '4 часа')
  );
}

export function timeDiff(timestamp1: number, timestamp2: number) {
  // Convert timestamps from seconds to milliseconds
  const diffInMs = Math.abs(timestamp2 * 1000 - timestamp1 * 1000);

  // Calculate the difference in days, hours, minutes, and seconds
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

  // Format the output string
  if (days === 0 && hours === 0 && minutes === 0) {
    return `${seconds}s`;
  } else if (days === 0 && hours === 0) {
    return `${minutes}m ${seconds}s`;
  } else if (days === 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else {
    return `${days}d ${hours}h ${minutes}m`;
  }
}
