import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {ApiWalletTransactionsData, OperationType, Status} from "../../../types/transactions.types";
import {useStores} from "@hooks/useStores";
import useTransactionDetails from "@hooks/useTransactionDetails";
import {observer} from "mobx-react-lite";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import FormattedNumber from "@components/common/FormattedNumber";
import {getEllipsisTxt} from "@helpers/formatters";
import TokenLogo from "@components/common/TokenLogo";
import copy from "@assets/icons/copy.svg";
import arrow from "@assets/icons/arrow-right.svg";
import CopyButton from "@components/common/CopyButton";
import {chainLogo, chainName, chainTxExplorer} from "@helpers/chains";
import gas from "@assets/icons/gas.svg";
import {format} from "date-fns";
import sendArrow from "@assets/images/send-arrow.svg";
import BigNumber from "bignumber.js";
import { useTranslation } from 'react-i18next';

interface TransactionDetailsProps {
  onClose: () => void;
  item: ApiWalletTransactionsData;
}

const TransactionDetails = observer(({onClose, item}: TransactionDetailsProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(true);
  const { accountStore } = useStores();
  const { assets } = accountStore;

  const {
    chain,
    status,
    operation,
    logoIn,
    logoOut,
    symbolIn,
    symbolOut,
    amountIn,
    amountOut,
    approveLogo,
    approveSymbol,
    approveName,
    tokenIn,
    tokenOut,
    sentTo,
    sentFrom,
    transactionHash,
    fee,
    feeToken,
    feePrice
  } = useTransactionDetails(item, assets);

  const isTradeOrSend = operation === OperationType.Trade || operation === OperationType.Send;

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} onExited={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-capitalize">
              {t(`operation.${operation}`)}
              {' '}
              {status === Status.Pending && <>⌛</>}
              {status === Status.Failed && <>🚫</>}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`card bg-semi-transparent-10 ${operation === OperationType.Trade ? '' : 'mb-3'}`}>
            {operation === OperationType.Trade && (
              <div className="tx-13 tx-muted mb-1">{t('common.sent')}</div>
            )}
            <div className="d-flex flex-row align-items-center justify-content-start">
              <HistoryLogo
                type={OperationType.Receive}
                symbolIn={approveSymbol || (isTradeOrSend ? symbolOut : symbolIn) || t('common.na')}
                mainLogo={approveLogo || (isTradeOrSend ? logoOut : logoIn)}
              />
              {operation === OperationType.Approve && (
                <div className="ms-2 me-auto">
                  {approveName || 'N/A'}
                </div>
              )}
              {operation !== OperationType.Approve && (
                <div className="ms-2 me-auto">
                  <FormattedNumber
                    value={(isTradeOrSend ? amountOut : amountIn) * (!isTradeOrSend ? 1 : -1)}
                    postfix={isTradeOrSend ? symbolOut : symbolIn}
                    subZeros
                    withSign
                    className={`d-block tx-semibold tx-17 ${!isTradeOrSend ? 'tx-success' : 'tx-white'}`}
                  />
                  <FormattedNumber
                    value={(isTradeOrSend ? amountOut : amountIn) * ((isTradeOrSend ? tokenOut?.price : tokenIn?.price) || 0)}
                    suffix="$"
                    className={`d-block tx-muted tx-13`}
                  />
                </div>
              )}
            </div>
          </div>

          {operation === OperationType.Trade && (
            <>
              <div className="swap-separator between-cards in-modal">
                <img src={sendArrow} alt="Swap" className="wd-50 ht-50" />
              </div>

              <div className="card bg-semi-transparent-10 mb-3">
                <div className="tx-13 tx-muted mb-1">{t('common.received')}</div>
                <div className="d-flex flex-row align-items-center justify-content-start">
                  <HistoryLogo
                    type={OperationType.Receive}
                    symbolIn={symbolIn || t('common.na')}
                    mainLogo={logoIn}
                  />
                  <div className="ms-2 me-auto">
                    <FormattedNumber
                      value={amountIn}
                      postfix={symbolIn}
                      withSign
                      className={`d-block tx-semibold tx-17 tx-succes`}
                    />
                    <FormattedNumber
                      value={amountIn * (tokenIn?.price || 0)}
                      suffix="$"
                      className={`d-block tx-muted tx-13`}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {operation !== OperationType.Trade && (
            <div className="card bg-semi-transparent-10 py-1 mb-3">
              {(operation === OperationType.Send || operation === OperationType.Receive) && (
                <>
                  <div className="py-2 border-bottom border-semi-transparent">
                    <div className="tx-13 tx-muted mb-1">{t('common.from')}</div>
                    <CopyButton text={sentFrom || ''} className="btn btn-link p-0 tx-left text-decoration-none wd-100p" noIcon>
                      <div className="d-flex align-items-center justify-content-start">
                        <TokenLogo address={sentFrom} name={sentFrom} size={25} />
                        <div className="ms-2 me-auto">{getEllipsisTxt(sentFrom, 4, '0x')}</div>
                        <img src={copy} alt="copy" />
                      </div>
                    </CopyButton>
                  </div>
                  <div className="py-2 border-bottom border-semi-transparent">
                    <div className="tx-13 tx-muted mb-1">{t('common.to')}</div>
                    <CopyButton text={sentTo || ''} className="btn btn-link p-0 tx-left text-decoration-none wd-100p" noIcon>
                      <div className="d-flex align-items-center justify-content-start">
                        <TokenLogo address={sentTo} name={sentTo} size={25} />
                        <div className="ms-2 me-auto">{getEllipsisTxt(sentTo, 4, '0x')}</div>
                        <img src={copy} alt="copy" />
                      </div>
                    </CopyButton>
                  </div>
                </>
              )}
              <div className="py-2">
                <div className="tx-13 tx-muted mb-1">{t('common.hash')}</div>
                <a
                  className="d-flex align-items-center justify-content-start tx-white text-decoration-none"
                  href={chainTxExplorer(chain, transactionHash)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="me-auto">{getEllipsisTxt(transactionHash, 4, '0x')}</div>
                  <img src={arrow} alt="open" />
                </a>
              </div>
            </div>
          )}

          <div className="card bg-semi-transparent-10 py-1">
            <div className="py-2 border-bottom border-semi-transparent">
              <div className="tx-13 tx-muted mb-1">{t('common.network')}</div>
              <div className="d-flex align-items-center justify-content-start">
                <img src={chainLogo(chain)} alt={chainName(chain)} width={25} />
                <div className="ms-2">{chainName(chain)}</div>
              </div>
            </div>

            {operation === OperationType.Trade && (
              <>
                <div className="py-2 border-bottom border-semi-transparent">
                  <div className="tx-13 tx-muted mb-1">{t('common.rate')}</div>
                  <div className="d-flex align-items-center justify-content-start">
                    {!!logoIn && <img src={logoIn} alt={symbolIn} width={25} className="me-2" />}
                    <div className="d-flex justify-content-start align-items-center tx-17">
                      <FormattedNumber
                        value={new BigNumber(tokenOut?.price || 0).div(tokenIn?.price || 1).toNumber()}
                        postfix={symbolIn}
                        subZeros
                        decimals={6}
                      />
                      <span className="mx-1">=</span>
                      <span>1 {symbolOut}</span>
                    </div>
                  </div>
                </div>
                <div className="py-2 border-bottom border-semi-transparent">
                  <div className="tx-13 tx-muted mb-1">{t('common.hash')}</div>
                  <a
                    className="d-flex align-items-center justify-content-start tx-white text-decoration-none"
                    href={chainTxExplorer(chain, transactionHash)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="me-auto">{getEllipsisTxt(transactionHash, 4, '0x')}</div>
                    <img src={arrow} alt="open" />
                  </a>
                </div>
              </>
            )}

            <div className="py-2">
              <div className="tx-13 tx-muted mb-1">{t('common.fee')}</div>
              <div className="d-flex align-items-center justify-content-start">
                <img src={gas} alt="Gas" width={25} className="me-2" />
                <FormattedNumber
                  value={fee}
                  decimals={6}
                  postfix={feeToken}
                />
                {!!feePrice && (
                  <FormattedNumber
                    value={feePrice}
                    suffix="($"
                    postfix=")"
                    className="ms-1"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="mt-3 pb-4 tx-center">
            {status === Status.Confirmed && <div className="tx-success">{t('common.successful')}</div>}
            {status === Status.Pending && <div className="tx-warning">{t('common.processing')}</div>}
            {status === Status.Failed && <div className="tx-danger">{t('common.canceled-failed')}</div>}
            <div className="tx-13 tx-muted">{format(new Date(item.date), 'dd.MM.yyyy HH:mm:ss')}</div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default TransactionDetails;
