import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useStores } from '@hooks/useStores';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import useResponseHandler from '@hooks/useResponseHandler';
import IconWithLoading from '@components/common/IconWithLoading';
import { observer } from 'mobx-react-lite';
import BadgeIconNew from '@components/common/BadgeIconNew';
import warning from '@assets/icons/warning-red.svg';
import { QuickMode } from '../../../types';

interface DeletePresetModalProps {
  preset: QuickMode | null;
  onClose: () => void;
}

const DeletePresetModal = observer(({ preset, onClose }: DeletePresetModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { quickTradeStore } = useStores();
  const handleResponse = useResponseHandler();

  const handleClose = () => {
    onClose();
  };

  const handleDelete = async () => {
    if (!preset) return;

    setLoading(true);
    const response = await quickTradeStore.deletePreset(preset.id);
    setLoading(false);

    if (!(response instanceof AxiosError)) {
      handleClose();
    } else {
      handleResponse(response.response);
    }
  };

  useEffect(() => {
    setShowModal(!!preset);
  }, [preset]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        onExited={handleClose}
      >
        <Modal.Header className="modal-header-new with-border" closeButton />
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center wd-100p tx-center pt-2">
          <BadgeIconNew className="mb-3" variant="danger">
            <img src={warning} alt="warning" width={20} />
          </BadgeIconNew>

          <div className="tx-18 tx-semibold tx-center mb-3">
            {t('quick-trade.delete-preset', { name: preset?.name })}
          </div>

          <button
            className="btn btn-danger wd-100p mt-3 bg-semi-danger-10 tx-danger"
            onClick={handleDelete}
            disabled={loading}
          >
            <IconWithLoading isLoading={loading} className="me-2" />
            {t('common.delete')}
          </button>

          <button
            className="btn btn-secondary tx-white wd-100p mt-2 mb-3"
            onClick={handleClose}
          >
            {t('common.close')}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default DeletePresetModal;
