import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { useStores } from '@hooks/useStores';
import PageLoader from '@components/PageLoader';
import { ChainId } from '../../constants';
import IconWithLoading from '@components/common/IconWithLoading';
import { useTranslation } from 'react-i18next';
import QuickTradeSettings from '@pages/QuickTrade/components/QuickTradeSettings';
import useResponseHandler from '@hooks/useResponseHandler';

const QuickTradePreset = observer(() => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { accountStore, quickTradeStore } = useStores();
  const { network } = accountStore;
  const { quickTradeSettingsLoaded, currency } = quickTradeStore;
  const navigate = useNavigate();
  const handleResponse = useResponseHandler();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSave = () => {
    setIsSaving(true);
    quickTradeStore.updatePreset().then((response) => {
      setIsSaving(false);
      if (Array.isArray(response)) {
        navigate(-1);
      } else {
        handleResponse(response, true);
      }
    }).catch((e) => {
      setIsSaving(false);
      handleResponse(e, true);
    })
  };

  useEffect(() => {
    quickTradeStore.initialize(id);
  }, [id]);

  useEffect(() => {
    quickTradeStore.setChain(network || ChainId.ETHER);
  }, [network]);

  useEffect(() => {
    return () => {
      quickTradeStore.reset();
    }
  }, []);

  if (!currency || !quickTradeSettingsLoaded) {
    return <PageLoader />;
  }

  return (
    <div>
      <QuickTradeSettings />

      <button
        className="btn btn-primary wd-100p"
        onClick={handleSave}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving} className="me-2" />
        {t('common.save')}
      </button>
    </div>
  );
});

export default QuickTradePreset;
