enum FooterAmplitudeEvent {
  WALLET_TAB_CLICKED = 'wallet_tab_clicked',
  RESEARCH_TAB_CLICKED = 'research_tab_clicked',
  TRADE_TAB_CLICKED = 'trade_tab_clicked',
  TEAM_TAB_CLICKED = 'team_tab_clicked',
  PROFILE_TAB_CLICKED = 'profile_tab_clicked',
}

enum OnboardingAmplitudeEvent {
  ONBOARDING_SLIDE_VIEWED = 'onboarding_slide_viewed',
  CREATE_WALLET_CLICKED = 'create_wallet_clicked',
  IMPORT_WALLET_CLICKED = 'import_wallet_clicked',
}

enum RegistrationAmplitudeEvent {
  PIN_PAGE_VIEWED = 'pin_page_viewed',
  PIN_ENTRY_STARTED = 'pin_entry_started',
  PIN_ENTRY_COMPLETED = 'pin_entry_completed',
  PIN_CONFIRMATION_STARTED = 'pin_confirmation_started',
  PIN_CONFIRMATION_COMPLETED = 'pin_confirmation_completed',
  PIN_CONFIRMATION_ERROR = 'pin_confirmation_error',
}

enum WalletCreatedAmplitudeEvent {
  WALLET_CREATED = 'wallet_created',
  SEED_PHRASE_COPY_CONTINUED = 'seed_phrase_copy_continued',
  ASSET_CLICKED_MAIN_SCREEN = 'asset_clicked_main_screen',
  HISTORY_ITEM_CLICKED = 'history_item_clicked',
  SPAM_TOGGLED_ASSETS = 'spam_toggled_assets',
  SPAM_TOGGLED_HISTORY = 'spam_toggled_history',
}

enum ResearchAmplitudeEvent {
  RESEARCH_SCREEN_VIEWED = 'research_screen_viewed',
  DISCLAIMER_AGREED = 'disclaimer_agreed',
  TOKEN_CHART_TAB_CLICKED = 'token_chart_tab_clicked',
  TOKEN_ANALYTIC_TAB_CLICKED = 'token_analytic_tab_clicked',
  TOKEN_TRADE_TAB_CLICKED = 'token_trade_tab_clicked',
  SHARE_BUTTON_CLICKED = 'share_button_clicked',
  NETWORK_SELECTION_CLICKED = 'network_selection_clicked',
  NETWORK_SELECTED = 'network_selected',
  TOKEN_SEARCH_SUCCESS = 'token_search_success',
  TOKEN_SEARCH_NOT_FOUND = 'token_search_not_found',
  TOKEN_SEARCH_INVALID_ADDRESS = 'token_search_invalid_address',
  BLOCK_XRAY_CLICKED = 'block_xray_clicked',
  SNIPE_SCOPE_CLICKED = 'snipe_scope_clicked',
  RESEARCH_INVITE_LINK_CLICKED = 'research_invite_link_clicked',
}

enum WalletAmplitudeEvent {
  MAIN_SCREEN_VIEWED = 'main_screen_viewed',
  RECEIVE_BUTTON_CLICKED = 'receive_button_clicked',
  BRIDGE_BUTTON_CLICKED = 'bridge_button_clicked',
  SEND_BUTTON_CLICKED = 'send_button_clicked',
  ASSETS_TAB_CLICKED = 'assets_tab_clicked',
  HISTORY_TAB_CLICKED = 'history_tab_clicked',
  AI_ASSISTANT_CLICKED = 'ai_assistant_clicked',
  ALL_CHAINS_CLICKED = 'all_chains_clicked',
  ACCOUNT_ICON_CLICKED = 'account_icon_clicked',
}

enum OrderTradeAmplitudeEvent {
  ORDER_CREATION_SCREEN_VIEWED = 'order_creation_screen_viewed',
  CHART_TAB_CLICKED = 'chart_tab_clicked',
  ANALYTIC_TAB_CLICKED = 'analytic_tab_clicked',
  ORDER_BUY_BUTTON_CLICKED = 'order_buy_button_clicked',
  ORDER_SELL_BUTTON_CLICKED = 'order_sell_button_clicked',
  MARKET_BUTTON_CLICKED = 'market_button_clicked',
  LIMIT_BUTTON_CLICKED = 'limit_button_clicked',
  ALPHA_BUTTON_CLICKED = 'alpha_button_clicked',
  STOP_LOSS_BUTTON_CLICKED = 'stop_loss_button_clicked',
  CREATE_ORDER_BUTTON_CLICKED = 'create_order_button_clicked',
  UPDATE_ORDER_BUTTON_CLICKED = 'update_order_button_clicked',
  ORDER_SETTINGS_CLICKED = 'order_settings_clicked',
  SAVE_SETTINGS_BUTTON_CLICKED = 'save_settings_button_clicked',
  RESET_TO_DEFAULT_BUTTON_CLICKED = 'reset_to_default_button_clicked',
}

enum ReferralAmplitudeEvent {
  REFERRAL_PROGRAM_SCREEN_VIEWED = 'referral_program_screen_viewed',
  CLAIM_BUTTON_CLICKED = 'claim_button_clicked',
  REFERRAL_LEVEL_SELECTED = 'referral_level_selected',
  REFERRAL_LINK_CLICKED = 'referral_link_clicked',
  QR_CODE_CLICKED = 'qr_code_clicked',
}

enum ProfileAmplitudeEvent {
  PROFILE_SCREEN_VIEWED = 'profile_screen_viewed',
  NEXT_RELEASES_BUTTON_CLICKED = 'next_releases_button_clicked',
  WALLET_SETTINGS_CLICKED = 'wallet_settings_clicked',
  EXPORT_SEED_PHRASE_CLICKED = 'export_seed_phrase_clicked',
  SECURITY_SETTINGS_CLICKED = 'security_settings_clicked',
  LANGUAGE_SETTINGS_CLICKED = 'language_settings_clicked',
  CUSTOMIZATION_SETTINGS_CLICKED = 'customization_settings_clicked',
  SUPPORT_CLICKED = 'support_clicked',
  ABOUT_US_CLICKED = 'about_us_clicked',
  PRIVACY_POLICY_CLICKED = 'privacy_policy_clicked',
  TERMS_CONDITIONS_CLICKED = 'terms_conditions_clicked',
  LOGOUT_CLICKED = 'logout_clicked',
}

enum CustomizationAmplitudeEvent {
  CUSTOMIZATION_SETTINGS_SAVED = 'customization_settings_saved',
  CUSTOMIZATION_SETTINGS_ERROR = 'customization_settings_error',
}

enum SecurityAmplitudeEvent {
  ENABLE_PIN_CLICKED = 'enable_pin_clicked',
  CHANGE_PIN_CLICKED = 'change_pin_clicked',
  DISABLE_PIN_CLICKED = 'disable_pin_clicked',
}

enum ReceiveAmplitudeEvent {
  COPY_ADDRESS_BUTTON_CLICKED = 'copy_address_button_clicked',
  SHARE_ADDRESS_BUTTON_CLICKED = 'share_address_button_clicked',
  RECEIVE_WARNING_VIEWED = 'receive_warning_viewed',
}

enum WalletSelectionAmplitudeEvent {
  WALLET_MANAGEMENT_SCREEN_VIEWED = 'wallet_management_screen_viewed',
  WALLETS_FILTER_CLICKED = 'wallets_filter_clicked',
  WALLET_SETTINGS_ICON_CLICKED = 'wallet_settings_icon_clicked',
  CREATE_WALLET_BUTTON_CLICKED = 'create_wallet_button_clicked',
  IMPORT_WALLET_BUTTON_CLICKED = 'import_wallet_button_clicked',
  ADD_WALLET_BUTTON_CLICKED = 'import_wallet_button_clicked',
}

enum LimitOrdersAmplitudeEvent {
  STORY_SHARE_CLICKED = 'story_share_clicked',
}

enum ClaimAmplitudeEvent {
  CLAIM_CLICKED = 'claim_clicked',
  CLAIM_CANCELLED = 'claim_cancelled',
  CLAIM_SUCCESS = 'claim_success',
  CLAIM_NETWORK_SELECTED = 'claim_network_selected',
}

enum AssetAmplitudeEvent {
  TOKEN_BUY_CLICKED = 'token_buy_clicked',
  TOKEN_SELL_CLICKED = 'token_sell_clicked',
  TOKEN_TRANSFER_CLICKED = 'token_transfer_clicked',
  TOKEN_CONTRACT_ADDRESS_COPIED = 'token_contract_address_copied',
  TOKEN_VIEWED = 'token_viewed',
  TOKEN_TRANSFER_SEND_CLICKED = 'token_transfer_send_clicked',
  TOKEN_TRANSFER_RECEIVE_CLICKED = 'token_transfer_receive_clicked',
  TOKEN_HISTORY_ITEM_CLICKED = 'token_history_item_clicked',
}

enum TokenOrderAmplitudeEvent {
  TOKEN_PAGE_BUY_CLICKED = 'token_page_buy_clicked',
  TOKEN_PAGE_SELL_CLICKED = 'token_page_sell_clicked',
  TOKEN_PAGE_CONTRACT_ADDRESS_COPIED = 'token_page_contract_address_copied',
  TOKEN_PAGE_VIEWED = 'token_page_viewed',
  TOKEN_PAGE_EXECUTED_TAB_CLICKED = 'token_page_executed_tab_clicked',
  TOKEN_PAGE_ACTIVE_TAB_CLICKED = 'token_page_active_tab_clicked',
  TOKEN_PAGE_CHART_CLICKED = 'token_page_chart_clicked',
  TOKEN_PAGE_ANALYTIC_CLICKED = 'token_page_analytic_clicked',
}

enum QuickTradeAmplitudeEvent {
  QUICK_TRADE_PAGE_VIEWED = 'quick_trade_page_viewed',
}

enum BridgeAmplitudeEvent {
  BRIDGE_FROM_CHAIN_SELECTED = 'bridge_from_chain_selected',
  BRIDGE_TO_CHAIN_SELECTED = 'bridge_to_chain_selected',
  BRIDGE_MAX_BUTTON_CLICKED = 'bridge_max_button_clicked',
  BRIDGE_RECEIVER_SELECTED = 'bridge_receiver_selected',
  BRIDGE_CONTINUE_CLICKED = 'bridge_continue_clicked',
  BRIDGE_CONFIRM_CLICKED = 'bridge_confirm_clicked',
}

enum SendAmplitudeEvent {
  SEND_TOKEN_SELECTED = 'send_token_selected',
  SEND_MAX_BUTTON_CLICKED = 'send_max_button_clicked',
  SEND_WALLET_SELECTED = 'send_wallet_selected',
  SEND_QR_CODE_CLICKED = 'send_qr_code_clicked',
  SEND_CONTINUE_CLICKED = 'send_continue_clicked',
  SEND_CONFIRMATION_CLICKED = 'send_confirmation_clicked',
  SEND_CANCEL_CLICKED = 'send_cancel_clicked',
}

enum WhaleSignalsAmplitudeEvent {
  WHALE_LOCATOR_PAGE_VIEWED = 'whale_locator_page_viewed',
  WHALE_LOCATOR_INFO_CLICKED = 'whale_locator_info_clicked',
  WHALE_LOCATOR_INVITE_LINK_CLICKED = 'whale_locator_invite_link_clicked',
  WHALE_LOCATOR_CHART_TAB_CLICKED = 'whale_locator_chart_tab_clicked',
  WHALE_LOCATOR_ANALYTIC_TAB_CLICKED = 'whale_locator_analytic_tab_clicked',
  WHALE_LOCATOR_TRADE_TAB_CLICKED = 'whale_locator_trade_tab_clicked',
  WHALE_LOCATOR_SHARE_BUTTON_CLICKED = 'whale_locator_share_button_clicked',
}

export default {
  ...FooterAmplitudeEvent,
  ...OnboardingAmplitudeEvent,
  ...RegistrationAmplitudeEvent,
  ...WalletCreatedAmplitudeEvent,
  ...ResearchAmplitudeEvent,
  ...WalletAmplitudeEvent,
  ...OrderTradeAmplitudeEvent,
  ...ReferralAmplitudeEvent,
  ...ProfileAmplitudeEvent,
  ...CustomizationAmplitudeEvent,
  ...SecurityAmplitudeEvent,
  ...ReceiveAmplitudeEvent,
  ...WalletSelectionAmplitudeEvent,
  ...LimitOrdersAmplitudeEvent,
  ...ClaimAmplitudeEvent,
  ...AssetAmplitudeEvent,
  ...TokenOrderAmplitudeEvent,
  ...QuickTradeAmplitudeEvent,
  ...BridgeAmplitudeEvent,
  ...SendAmplitudeEvent,
  ...WhaleSignalsAmplitudeEvent,
};
