import React from 'react';
import { PageRoutes } from './constants';
import Welcome from '@pages/Wallet/Welcome';
import CreateWallet from '@pages/Wallet/CreateWallet';
import WalletCreated from '@pages/Wallet/WalletCreated';
import GetSeedPhrase from '@pages/Wallet/GetSeedPhrase';
import SeedPhrase from '@pages/Wallet/SeedPhrase';
import WalletReady from '@pages/Wallet/WalletReady';
import Wallet from '@pages/Wallet';
import Asset from 'pages/Asset';
import Receive from '@pages/Receive';
import Send, { SendPageType } from '@pages/Send';
import Login from '@pages/Login/Login';
import ComingSoon from '@pages/Wallet/ComingSoon';
import Profile from 'pages/Profile';
import ChangePin from '@pages/Profile/ChangePin';
import Language from '@pages/Profile/Language';
import SnipeSettings from '@pages/Profile/SnipeSettings';
import ExportSeedPhrase from '@pages/Profile/ExportSeedPhrase';
import TokenSnipe from '@pages/TokenSnipe';
import PositionProcessing from '@pages/TokenSnipe/PositionProcessing';
import SnipeDetails from '@pages/TokenSnipe/SnipeDetails';
import WhaleSignals from '@pages/WhaleSignals';
import PositionSettings from '@pages/TokenSnipe/components/PositionSettings';
import ImportWallet from '@pages/Wallet/ImportWallet';
import StrategySettings from '@pages/AutoTrade/components/StrategySettings';
import StrategyFilters from '@pages/AutoTrade/components/StrategyFilters';
import Referral from '@pages/Referral';
import Research from '@pages/Research';
import Customization from '@pages/Profile/Customization';
import LimitOrders from '@pages/LimitOrders';
import LimitOrdersToken from '@pages/LimitOrders/LimitOrdersToken';
import LimitOrdersTrade from '@pages/LimitOrders/LimitOrdersTrade';
import NewWallet from '@pages/Wallet/NewWallet';
import WalletName from '@pages/Wallet/WalletName';
import PrivateKeyImport from '@pages/Wallet/PrivateKeyImport';
import LimitOrdersHidden from '@pages/LimitOrders/LimitOrdersHidden';
import WalletSettings from '@pages/Profile/WalletSettings';
import ExportPrivateKey from '@pages/Profile/ExportPrivateKey';
import Bridge from '@pages/Bridge';
import BridgeConfirm from '@pages/Bridge/BridgeConfirm';
import NoSolanaWallet from '@pages/Wallet/NoSolanaWallet';
import NoTonWallet from '@pages/Wallet/NoTonWallet';
import Security from '@pages/Profile/Security';
import NewWalletOperation from '@pages/Wallet/NewWalletOperation';
import QuickTrade from '@pages/QuickTrade';
import QuickTradePreset from '@pages/QuickTrade/QuickTradePreset';

const routes = [
  {
    path: PageRoutes.HOME,
    component: <Welcome />,
  },
  {
    path: PageRoutes.LOGIN,
    component: <Login />,
  },
  {
    path: PageRoutes.CREATE_WALLET,
    component: <CreateWallet />,
  },
  {
    path: PageRoutes.IMPORT_PIN,
    component: <CreateWallet isImport />,
  },
  {
    path: PageRoutes.IMPORT_WALLET,
    component: <ImportWallet />,
  },
  {
    path: PageRoutes.WALLET_CREATED,
    component: <WalletCreated />,
  },
  {
    path: PageRoutes.GET_SEED_PHRASE,
    component: <GetSeedPhrase />,
  },
  {
    path: PageRoutes.SEED_PHRASE,
    component: <SeedPhrase isRegister />,
  },
  {
    path: PageRoutes.WALLET_READY,
    component: <WalletReady />,
  },
  {
    path: PageRoutes.WALLET,
    component: <Wallet />,
    isPrivate: true,
  },
  {
    path: PageRoutes.RECEIVE,
    component: <Receive />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SEND,
    component: <Send />,
    isPrivate: true,
  },
  {
    path: '/swap/*',
    component: <Send type={SendPageType.SWAP} />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SEND_CONFIRM,
    component: <Send type={SendPageType.CONFIRM} />,
    isPrivate: true,
  },
  {
    path: PageRoutes.ASSET,
    component: <Asset />,
    isPrivate: true,
  },
  {
    path: PageRoutes.POSITION_PROCESSING,
    component: <PositionProcessing />,
    isPrivate: true,
  },
  {
    path: PageRoutes.POSITION_PROCESSING_BOT,
    component: <PositionProcessing isBot />,
    isPrivate: true,
  },
  {
    path: PageRoutes.TOKEN_SNIPE_POSITION,
    component: <SnipeDetails />,
    isPrivate: true,
  },
  {
    path: PageRoutes.TOKEN_SNIPE_POSITION_BOT,
    component: <SnipeDetails isBot />,
    isPrivate: true,
  },
  {
    path: PageRoutes.TOKEN_SNIPE,
    component: <TokenSnipe />,
    isPrivate: true,
  },
  {
    path: PageRoutes.LIMIT_ORDERS_TOKEN,
    component: <LimitOrdersToken />,
    isPrivate: true,
  },
  {
    path: PageRoutes.LIMIT_ORDERS_TRADE,
    component: <LimitOrdersTrade />,
    isPrivate: true,
  },
  {
    path: PageRoutes.LIMIT_ORDERS_UPDATE,
    component: <LimitOrdersTrade isUpdate />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_SNIPE,
    component: <ComingSoon />,
    isPrivate: true,
  },
  {
    path: PageRoutes.LIMIT_ORDERS,
    component: <LimitOrders />,
    isPrivate: true,
  },
  {
    path: PageRoutes.LIMIT_ORDERS_HIDDEN,
    component: <LimitOrdersHidden />,
    isPrivate: true,
  },
  {
    path: PageRoutes.PROFILE,
    component: <Profile />,
    isPrivate: true,
  },
  {
    path: PageRoutes.RESEARCH,
    component: <Research />,
    isPrivate: true,
  },
  {
    path: PageRoutes.CHANGE_PIN,
    component: <ChangePin />,
    isPrivate: true,
  },
  {
    path: PageRoutes.WALLET_SETTINGS,
    component: <WalletSettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.EXPORT_PRIVATE_KEY,
    component: <ExportPrivateKey />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SNIPE_SETTINGS,
    component: <SnipeSettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.BOT_SETTINGS,
    component: <SnipeSettings isBot />,
    isPrivate: true,
  },
  {
    path: PageRoutes.POSITION_SETTINGS,
    component: <PositionSettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.EXPORT_SEED_PHRASE,
    component: <ExportSeedPhrase />,
    isPrivate: true,
  },
  {
    path: PageRoutes.WHALE_SIGNALS,
    component: <WhaleSignals />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE,
    component: <ComingSoon />,
    // component: <AutoTrade />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE_CREATE,
    component: <StrategySettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE_EDIT,
    component: <StrategySettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE_FILTERS,
    component: <StrategyFilters />,
    isPrivate: true,
  },
  {
    path: PageRoutes.REFERRAL,
    component: <Referral />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AMOUNT_SETTINGS,
    component: <Customization />,
    isPrivate: true,
  },
  {
    path: PageRoutes.NEW_WALLET,
    component: <NewWallet />,
    isPrivate: true,
  },
  {
    path: PageRoutes.NEW_WALLET_OPERATION,
    component: <NewWalletOperation />,
    isPrivate: true,
  },
  {
    path: PageRoutes.NEW_WALLET_CREATE,
    component: <NewWalletOperation />,
    isPrivate: true,
  },
  {
    path: PageRoutes.NEW_WALLET_IMPORT,
    component: <NewWalletOperation />,
    isPrivate: true,
  },
  {
    path: PageRoutes.NEW_WALLET_KEY,
    component: <ExportPrivateKey isCreate />,
    isPrivate: true,
  },
  {
    path: PageRoutes.NEW_WALLET_NAME,
    component: <WalletName />,
    isPrivate: true,
  },
  {
    path: PageRoutes.NEW_WALLET_KEY_IMPORT,
    component: <PrivateKeyImport />,
    isPrivate: true,
  },
  {
    path: PageRoutes.NO_SOLANA_WALLET,
    component: <NoSolanaWallet />,
    isPrivate: true,
  },
  {
    path: PageRoutes.NO_TON_WALLET,
    component: <NoTonWallet />,
    isPrivate: true,
  },
  {
    path: PageRoutes.BRIDGE,
    component: <Bridge />,
    isPrivate: true,
  },
  {
    path: PageRoutes.BRIDGE_CONFIRM,
    component: <BridgeConfirm />,
    isPrivate: true,
  },
  {
    path: PageRoutes.LANGUAGE,
    component: <Language />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SECURITY,
    component: <Security />,
    isPrivate: true,
  },
  {
    path: PageRoutes.QUICK_TRADE,
    component: <QuickTrade />,
    isPrivate: true,
  },
  {
    path: PageRoutes.QUICK_TRADE_NEW,
    component: <QuickTradePreset />,
    isPrivate: true,
  },
  {
    path: PageRoutes.QUICK_TRADE_EDIT,
    component: <QuickTradePreset />,
    isPrivate: true,
  },
];

export default routes;
