import FormattedNumber from '@components/common/FormattedNumber';
import InputGroup from '@components/common/InputGroup';
import BigNumber from 'bignumber.js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PercentButtons from './PercentButtons';
import { isNaN } from '@helpers/bignumber';

type Props = {
  label: string;
  currentValue: string | number;
  level: string | number | null;
  levelPercents: string | number | null;
  percentButtons: number[];
  anotherLevel: (string | number | null)[];
  isInValid?: boolean;
  setLevel: (v: string | number | null) => void;
  setLevelPercents: (v: string | number | null) => void;
};

const CustomLevel = ({
  label,
  currentValue,
  level,
  levelPercents,
  percentButtons,
  anotherLevel,
  isInValid = false,
  setLevel,
  setLevelPercents,
}: Props) => {
  const [anotherLevelValue, anotherLevelPercents] = anotherLevel;
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex align-items-center justify-content-start tx-muted mt-3 mb-2 tx-13">
        <div>{t(label)}:</div>
        <span
          className="cur-pointer"
          onClick={() => setLevel(anotherLevelValue || currentValue)}
        >
          <FormattedNumber
            value={anotherLevelValue || currentValue}
            decimals={4}
            subZeros
            suffix="$"
            className="tx-white ms-1 text-decoration-underline"
          />
          {!isNaN(anotherLevelPercents) && (
            <FormattedNumber
              value={anotherLevelPercents}
              className="tx-13 tx-white ms-1"
              suffix="("
              postfix="%)"
              nonAbsolute
              floor
            />
          )}
        </span>
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2">
          <InputGroup
            isInValid={isInValid}
            className="wd-65p"
            inputMode="decimal"
            unit="$"
            placeholder="0$"
            value={level}
            onChange={(v) => setLevel(v)}
          />
          <InputGroup
            isInValid={isInValid}
            className="wd-35p"
            inputMode="decimal"
            noCross
            unit="%"
            placeholder="0%"
            value={levelPercents}
            onChange={(v) => setLevelPercents(v)}
          />
        </div>
        <PercentButtons
          value={currentValue}
          buttons={percentButtons}
          onClick={setLevel}
        />
      </div>
    </>
  );
};

export default CustomLevel;
