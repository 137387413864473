import React, { useState } from 'react';
import info from 'assets/icons/info-circle.svg';
import stocks from '@assets/icons/stocks.svg';
import share from '@assets/icons/share.svg';
import check from '@assets/icons/audit-check.svg';
import uncheck from '@assets/icons/audit-uncheck.svg';
import unknown from '@assets/icons/audit-unknown.svg';
import { WhaleSignalResponse } from 'types/whale-signals/whale-signals.request';
import WhaleSignalAnomalyItem from './WhaleSignalAnomalyItem';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import AlphaLink from '@components/common/AlphaLink';
import { useStores } from '@hooks/useStores';
import { anomalyHourDiff } from '@helpers/anomalyHourDifference';
import { formatNumber } from '@helpers/numbers';
import timeAgo, { getDateFnsLocale } from '@helpers/timeAgo';
import TabsCard from '@components/common/TabsCard';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import Badge from '@components/common/Badge';
import useShareToken from '@hooks/useShareToken';
import { Blockchain } from 'types/enums';
import { observer } from 'mobx-react-lite';
import { useWhaleSignalsInfoModal } from 'pages/WhaleSignals/components/WhaleSignalsInfoModal';

const WhaleSignalItem = (props: WhaleSignalResponse) => {
  const { accountStore, settingsStore, whaleSignalsStore } = useStores();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [w, AmplitudeWrapper] = useAmplitude();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const handleShareToken = useShareToken();
  const showInfo = useWhaleSignalsInfoModal();

  const {
    currentPriceInPercent,
    maxPriceInPercent,
    volume,
    initialLiquidity,
    pairAge,
    holders,
    marketCap,
    currentLiquidity,
    lastUpdatedAt,
    token,
    score,
    security,
  } = props;

  const { refId } = accountStore;
  const { analyticsBot } = settingsStore;
  const { blockchain } = whaleSignalsStore;

  const distance = (value: Date | number) =>
    timeAgo(value, false, getDateFnsLocale(language));

  const metricsTab: [string, string | string[]][] = [
    ['signal.volume', volume ? formatNumber(volume, '$') : t('common.na')],
    ['signal.mcap', marketCap ? formatNumber(marketCap, '$') : t('common.na')],
    [
      'signal.initial-liq',
      initialLiquidity ? formatNumber(initialLiquidity, '$') : t('common.na'),
    ],
    [
      'signal.current-liq',
      currentLiquidity ? formatNumber(currentLiquidity, '$') : t('common.na'),
    ],
    ['order.age', pairAge ? distance(new Date(pairAge)) : t('common.na')],
    ['signal.last-update', distance(new Date(lastUpdatedAt))],
    [
      'common.holders',
      [formatNumber(holders[0].value), formatNumber(anomalyHourDiff(holders))],
    ],
  ];

  const isUnknown = (val: any, condition?: boolean) =>
    val === undefined ? undefined : condition ?? val;

  const securityTab: [string, undefined | 0 | boolean | number[]][] = [
    [
      'signal.liquidity-lock',
      isUnknown(security.lpLockPercent, security.lpLockPercent! > 90),
    ],
    ['signal.contract-verified', isUnknown(security.isOpenSource)],
    ['signal.honeypot', isUnknown(security.isHoneypot, !security.isHoneypot)],
    [
      'signal.owner-balance-less-5',
      isUnknown(security.ownerPercent, security.ownerPercent < 5),
    ],
    [
      'signal.is-hidden-owner',
      isUnknown(security.isHiddenOwner, !security.isHiddenOwner),
    ],
    ['signal.renounced', isUnknown(security.isRenounced)],
    ['common.tax', [security.buyTax, security.sellTax, security.transferTax]],
  ];

  const curPrice = currentPriceInPercent[0].value;
  const curPriceDiff = anomalyHourDiff(currentPriceInPercent);
  const maxPrice = maxPriceInPercent[0].value;
  const maxPriceDiff = anomalyHourDiff(maxPriceInPercent);

  const securityIssues = securityTab
    .slice(0, -1)
    .filter(([_, value]) => value === false).length;

  const handleTrade = () => {
    navigate(`/limit-orders/${blockchain}/${token.address}/trade`);
  };

  return (
    <div className="card gap-3">
      <Badge
        variant="custom"
        className={cn([
          'd-flex',
          'justify-content-center',
          'position-absolute',
          'z-index-200',
          'bg-teal',
          'bd-black-1',
          'pd-2',
          'mn-wd-15',
        ])}
      >
        <span className="tx-black tx-normal">{score[0].value}</span>
      </Badge>
      <WhaleSignalAnomalyItem {...props} />
      <div className="d-flex gap-12px">
        {[
          ['signal.current-price', curPrice, curPriceDiff],
          ['signal.max-price', maxPrice, maxPriceDiff],
        ].map(([label, price, diff], idx) => (
          <div
            key={'prices-' + idx}
            className="card py-2 wd-100p align-items-center bg-semi-transparent-10"
          >
            <span>
              <span className="tx-muted tx-13">{t(label as string)}</span>{' '}
              <img
                onClick={showInfo}
                src={info}
                className="tx-muted tx-13"
                width={13}
                height={13}
              />
            </span>
            <span className="tx-13">
              <span
                className={cn([
                  diff === price
                    ? +price > 0
                      ? 'tx-success'
                      : price === 0
                        ? 'tx-white'
                        : 'tx-danger'
                    : 'tx-white',
                ])}
              >
                {formatNumber(price, '%', 0)}
              </span>
              <span
                className={cn([
                  (+diff < 1 || diff === price) && 'd-none',
                  +diff > 0 ? 'tx-success' : 'tx-danger',
                ])}
              >
                {' '}
                {+diff > 0 ? '↗' : '↘'}{' '}
                {formatNumber(Math.abs(+diff), '%', 0)}
              </span>
            </span>
          </div>
        ))}
      </div>

      <div className="card bg-semi-transparent-10 pd-12">
        <TabsCard
          active={activeTab}
          onClick={setActiveTab}
          variant="inner"
          list={[
            {
              value: 0,
              label: <span className="tx-13">{t('signal.metrics')}</span>,
            },
            {
              value: 1,
              label: (
                <>
                  <span className="tx-13">{t('signal.security')}</span>
                  {!!securityIssues && (
                    <Badge
                      variant="danger"
                      className="ms-1 tx-13 tx-normal pd-y-4 pd-x-6"
                      label={securityIssues}
                    />
                  )}
                </>
              ),
            },
          ]}
        >
          {
            <div
              className={cn([
                'd-flex flex-wrap tab-element tab-0',
                activeTab !== 0 && 'd-none',
              ])}
            >
              {metricsTab.map(([label, value]) => (
                <div className="wd-50p text-nowrap" key={label}>
                  <span className="tx-13 tx-muted">{t(label)}: </span>
                  {!Array.isArray(value) ? (
                    <span className="tx-13">{value}</span>
                  ) : (
                    <>
                      <span className="tx-13">{value[0]}</span>
                      <span
                        className={cn([
                          'tx-13',
                          +value[1] === 0 && 'd-none',
                          +value[1] > 0 ? 'tx-success' : 'tx-danger',
                        ])}
                      >
                        {+value[1] > 0 ? '+' : '-'}
                        {Math.abs(+value[1])}
                      </span>
                    </>
                  )}
                </div>
              ))}
            </div>
          }
          <div
            className={cn([
              'd-flex flex-wrap tab-element tab-1',
              activeTab !== 1 && 'd-none',
            ])}
          >
            {securityTab.map(([label, value], idx) => (
              <div
                className={cn([idx !== securityTab.length - 1 && 'wd-50p'])}
                key={label}
              >
                {(value === true || Array.isArray(value)) && (
                  <img src={check} alt="Check" />
                )}
                {value === false && <img src={uncheck} alt="Uncheck" />}
                {value === undefined && <img src={unknown} alt="Unknown" />}
                <span className="tx-13 pd-l-6">{t(label)}</span>
                {Array.isArray(value) && (
                  <span className="tx-13 pd-l-6 tx-muted">
                    <span className="tx-success">
                      B: {Math.round(value[0])}%
                    </span>
                    {' / '}
                    <span className="tx-danger">
                      S: {Math.round(value[1])}%
                    </span>
                    {' / '}
                    <span className="tx-light-green">
                      T: {Math.round(value[2])}%
                    </span>
                  </span>
                )}
              </div>
            ))}
          </div>
        </TabsCard>
      </div>
      <div className="d-flex justify-content-between align-items-center wd-100p gap-2 z-index-10">
        <AmplitudeWrapper
          className="btn btn-semi-10 bg-transparent border-semi-transparent bd-1 d-flex align-items-center justify-content-center px-2 py-2 flex-grow-1 mn-wd-max-content"
          eventInput={AmplitudeEvent.WHALE_LOCATOR_CHART_TAB_CLICKED}
          eventProperties={{ token }}
        >
          <AlphaLink
            className="d-flex align-items-center"
            path={settingsStore.getChartLink(blockchain, token.address)}
          >
            <img src={stocks} alt="Stocks" width={16} />
          </AlphaLink>
        </AmplitudeWrapper>
        <AmplitudeWrapper
          className="d-flex align-items-center btn btn-semi-10 ht-32 py-2 wd-100p"
          eventInput={AmplitudeEvent.WHALE_LOCATOR_ANALYTIC_TAB_CLICKED}
          eventProperties={{ token }}
        >
          <AlphaLink
            className="mx-auto d-flex align-items-center tx-13 text-decoration-none text-white "
            path={`https://t.me/${analyticsBot}?start=${token.address}`}
          >
            {t('common.analytic')}
          </AlphaLink>
        </AmplitudeWrapper>
        <button
          className="d-flex align-items-center justify-content-center btn ht-32 btn-primary tx-black py-2 tx-13 wd-100p op-8 ht-40"
          onClick={w(
            handleTrade,
            AmplitudeEvent.WHALE_LOCATOR_TRADE_TAB_CLICKED,
            {
              token,
            },
          )}
        >
          {t('common.trade')}
        </button>
        <button
          className="btn btn-semi-10 bg-transparent border-semi-transparent bd-1 d-flex align-items-center justify-content-center px-2 py-2 flex-grow-1 mn-wd-max-content"
          onClick={w(
            () =>
              handleShareToken(
                blockchain as unknown as Blockchain,
                token.address,
                refId!,
              ),
            AmplitudeEvent.WHALE_LOCATOR_SHARE_BUTTON_CLICKED,
            { token },
          )}
        >
          <img src={share} alt="more" width={16} />
        </button>
      </div>
    </div>
  );
};

export default observer(WhaleSignalItem);
