import { useMemo } from 'react';
import { mockOrderResponse } from '../mocks';
import { OrderResponse } from '../types/order/order.response';
import {
  OrderStatus,
  OrderTransactionType,
  OrderType,
} from '../types/order/order.enum';
import timeAgo, { getDateFnsLocale } from '@helpers/timeAgo';
import BigNumber from 'bignumber.js';
import { OrderTrigger } from '../types/enums';
import { LimitData } from '../types/common.types';
import { useTranslation } from 'react-i18next';

const types = [
  OrderTrigger.PRICE_IN_USD,
  OrderTrigger.PRICE_IN_BASE_TOKENS,
  OrderTrigger.MCAP,
];

const useOrderData = (
  data: OrderResponse | null,
  limitData: LimitData,
  nativePrice = 1,
) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const buttons = [
    `${t('common.price')} $`,
    `${t('common.price')} %symbol`,
    `${t('common.mcap-shrt')}`,
  ];
  const item = useMemo(() => {
    return (
      data ||
      ({
        ...mockOrderResponse,
      } as OrderResponse)
    );
  }, [data]);
  const locale = getDateFnsLocale(language);

  const {
    createdAt,
    updatedAt,
    transactions = [],
    trigger,
    type,
    status,
    valueIn,
    valueOut,
    usdValue,
    nativeTokenUsdPrice,
    baseToken,
    tokenSymbol,
    tokenName,
    metadata,
    pairAddress,
    targetTriggerValue,
    initialTriggerValue,
    blockchain,
    dex,
    walletAddress,
    tokenAddress,
    id,
    pairType,
    settings,
  } = item;

  const { priceBase, price, mCap } = limitData;

  const isMarket = useMemo(() => {
    return type === OrderType.MARKET_BUY || type === OrderType.MARKET_SELL;
  }, [type]);

  const isBuy = useMemo(() => {
    return [
      OrderType.LIMIT_BUY,
      OrderType.MARKET_BUY,
      OrderType.STOP_BUY,
    ].includes(type);
  }, [type]);

  const isStopLoss = useMemo(() => {
    return type === OrderType.STOP_LOSS;
  }, [type]);

  const isError = useMemo(() => {
    return status === OrderStatus.ERROR;
  }, [status]);

  const isOpen = useMemo(() => {
    return [OrderStatus.OPEN, OrderStatus.WAITING].includes(status);
  }, [status]);

  const isCompleted = useMemo(() => {
    return status === OrderStatus.COMPLETED;
  }, [status]);

  const isExecuting = useMemo(() => {
    return status === OrderStatus.EXECUTING || (isMarket && isOpen);
  }, [status, isMarket, isOpen]);

  const errorText = useMemo(() => {
    return metadata?.errorTitle || t('common.transaction-failed');
  }, [metadata]);

  const errorLongText = useMemo(() => {
    return (
      metadata?.errorTitle ||
      metadata?.decodedError?.reason ||
      t('common.error-occurred')
    );
  }, [metadata]);

  const errorDescription = useMemo(() => {
    if (!metadata?.errorTitle) return null;
    switch (metadata.errorTitle) {
      case 'Low slippage':
        if (limitData?.liquidity === 0) return t('order.error-zero-liquidity');
        return t('order.error-low-slippage');
      case 'Token is not saleable':
        return t('order.error-not-saleable');
      case 'Reverted':
        return t('order.error-reverted');
      case 'Execution error':
        return t('order.error-execution-error');
      case 'Insufficient Gas tip':
        return t('order.error-insufficient-gas-tip');
      case 'Transaction expired':
        return t('order.error-transaction-expired');
      case 'Insufficient gas':
        return t('order.error-insufficient-gas');
      case 'Not enough tokens':
        return t('order.error-not-enough-tokens');
    }
    if (metadata.errorTitle.includes('Insufficient funds')) {
      return t('order.error-insufficient-funds');
    } else if (metadata.errorTitle.includes('Simulation failed')) {
      return t('order.error-simulation-failed');
    } else if (metadata.errorTitle.includes('Actual balance is')) {
      return t('order.error-actual-balance');
    } else if (metadata.errorTitle.includes('Token in not ready to')) {
      return t('order.error-token-not-ready-to-buy-sell');
    } else if (metadata.errorTitle.includes('Jito network overloaded')) {
      return t('order.error-jito-network-overloaded');
    }
    return null;
  }, [metadata]);

  const typeName = useMemo(() => {
    if (metadata.alphaOrder)
      return type.includes('BUY')
        ? t('order.alpha-buy-order')
        : t('order.alpha-sell-order');

    switch (type) {
      case OrderType.LIMIT_BUY:
        return t('order.limit-buy');
      case OrderType.LIMIT_SELL:
        return t('order.limit-sell');
      case OrderType.MARKET_BUY:
        return t('order.market-buy');
      case OrderType.MARKET_SELL:
        return t('order.market-sell');
      default:
        return t('common.stop-loss');
    }
  }, [type]);

  const typeClass = useMemo(() => {
    return isBuy ? 'operation-buy' : 'operation-sell';
  }, [isBuy]);

  const statusText = useMemo(() => {
    if (status === OrderStatus.EXECUTING) {
      return t('operation.executing');
    }
    if (status === OrderStatus.ERROR) {
      return t('operation.error');
    }
    if (status === OrderStatus.COMPLETED) {
      return t('operation.executed');
    }
    return t('operation.open');
  }, [status]);

  const statusTextDated = useMemo(() => {
    const updated = timeAgo(updatedAt, true, locale)?.replace(
      'меньше минуты назад',
      'минуту назад',
    );
    const created = timeAgo(createdAt, true, locale)?.replace(
      'меньше минуты назад',
      'минуту назад',
    );

    if (isExecuting) {
      return t('operation.executing');
    }
    if (status === OrderStatus.ERROR) {
      return `${t('operation.error')} ${updated}`;
    }
    if (status === OrderStatus.COMPLETED) {
      return updated;
    }
    return `${t('operation.created')} ${created}`;
  }, [status, createdAt, updatedAt]);

  const statusColor = useMemo(() => {
    if (status === OrderStatus.EXECUTING) {
      return 'warning';
    }
    if (status === OrderStatus.ERROR) {
      return 'danger';
    }
    if (status === OrderStatus.COMPLETED) {
      return 'success';
    }
    return 'white';
  }, [status, isExecuting]);

  const baseAmount = useMemo(() => {
    return new BigNumber(usdValue).div(nativeTokenUsdPrice).toNumber();
  }, [usdValue, nativeTokenUsdPrice]);

  const amountOut = useMemo(() => {
    if (isExecuting) {
      return new BigNumber(valueIn).multipliedBy(isBuy ? 1 : -1);
    }
    return new BigNumber(isBuy ? valueIn : valueOut).multipliedBy(
      isBuy ? -1 : 1,
    );
  }, [isBuy, valueIn, valueOut, isExecuting]);

  const amountIn = useMemo(() => {
    return new BigNumber(isBuy ? valueOut : valueIn).multipliedBy(
      isBuy ? 1 : -1,
    );
  }, [isBuy, valueIn, valueOut]);

  const usdPrice = useMemo(() => {
    return new BigNumber(usdValue).div(amountIn);
  }, [amountIn, usdValue]);

  const tokenNativePrice = useMemo(() => {
    return new BigNumber(amountOut).div(amountIn);
  }, [amountIn, amountOut]);

  const triggerName = useMemo(() => {
    if (isMarket) {
      return t('common.market');
    }
    return buttons[types.indexOf(trigger)]?.replace('%symbol', baseToken);
  }, [trigger, baseToken, isMarket]);

  const fee = useMemo(() => {
    return transactions.reduce((acc, tx) => {
      return acc.plus(tx.fee || 0);
    }, new BigNumber(0));
  }, [transactions]);

  const feeInUsd = useMemo(() => {
    return (transactions || []).reduce((acc, tx) => {
      return acc.plus(tx.feeInUsd || 0);
    }, new BigNumber(0));
  }, [transactions]);

  const actualNativeTokenUsdPrice = useMemo(() => {
    if (nativeTokenUsdPrice) {
      return nativeTokenUsdPrice;
    }
    return usdPrice.div(tokenNativePrice).toNumber();
  }, [nativeTokenUsdPrice, usdPrice, tokenNativePrice]);

  const currentData = useMemo(() => {
    let label = t('order-trigger.current-price');
    let value: string | number | undefined =
      trigger === OrderTrigger.PRICE_IN_USD ? price : priceBase;
    if (trigger === OrderTrigger.MCAP) {
      label = t('order-trigger.current-mcap');
      value = mCap;
    }
    return {
      label: label?.replace('%symbol', baseToken || ''),
      sign: initialTriggerValue <= targetTriggerValue ? '>' : '<',
      value: new BigNumber(value || 0).toNumber(),
      isUsd: trigger !== OrderTrigger.PRICE_IN_BASE_TOKENS,
    };
  }, [
    baseToken,
    price,
    priceBase,
    mCap,
    trigger,
    targetTriggerValue,
    initialTriggerValue,
  ]);

  const approveTransaction = useMemo(() => {
    return transactions.find((tx) => tx.type === OrderTransactionType.APPROVE);
  }, [transactions]);

  const buyTransaction = useMemo(() => {
    return transactions.find((tx) => tx.type === OrderTransactionType.BUY);
  }, [transactions]);

  const sellTransaction = useMemo(() => {
    return transactions.find((tx) => tx.type === OrderTransactionType.SELL);
  }, [transactions]);

  const limitPrice = useMemo(() => {
    if (isCompleted || isMarket || trigger === OrderTrigger.MCAP) {
      return 0;
    }
    const value = new BigNumber(valueIn);
    if (isBuy) {
      return value.multipliedBy(nativePrice).toNumber();
    }
    return value
      .multipliedBy(targetTriggerValue)
      .multipliedBy(
        trigger === OrderTrigger.PRICE_IN_BASE_TOKENS ? nativePrice : 1,
      )
      .toNumber();
  }, [
    isCompleted,
    isMarket,
    targetTriggerValue,
    valueIn,
    trigger,
    nativePrice,
    isBuy,
  ]);

  return {
    isMarket,
    isBuy,
    isStopLoss,
    isAlpha: !!metadata.alphaOrder,
    isError,
    isExecuting,
    isOpen,
    isCompleted,
    errorText,
    errorLongText,
    errorDescription,
    typeName,
    typeClass,
    statusText,
    statusTextDated,
    statusColor,
    baseAmount,
    amountOut,
    amountIn,
    tokenSymbol,
    tokenName,
    baseToken,
    valueIn,
    status,
    usdValue,
    usdPrice,
    pairAddress,
    createdAt,
    updatedAt,
    targetTriggerValue,
    initialTriggerValue,
    nextTriggerValue: metadata.alphaOrder?.nextTriggerValue,
    triggerName,
    trigger,
    nativeTokenUsdPrice: actualNativeTokenUsdPrice,
    tokenNativePrice,
    fee,
    feeInUsd,
    blockchain,
    dex,
    walletAddress,
    tokenAddress,
    id,
    pairType,
    currentData,
    transactions,
    approveTransaction,
    buyTransaction,
    sellTransaction,
    limitPrice,
    settings,
  };
};

export default useOrderData;
