import React, { useMemo } from 'react';
import PercentButtons from './PercentButtons';
import InputGroup from '@components/common/InputGroup';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

type Props = {
  disabled?: boolean;
  value: number | string | null;
  isInValid?: boolean;
  onChange: (val: number | string | null) => void;
};

const percentValues = [5, 10, 15, 25];

const LevelStep = (props: Props) => {
  const { t } = useTranslation();
  const { tokenTrade } = useStores();
  const { isBuy } = tokenTrade;

  const { onChange, disabled } = props;

  const percentButtons = useMemo(
    () => (isBuy ? percentValues.map((n) => -n) : percentValues),
    [isBuy],
  );

  return (
    <div className="d-flex flex-column gap-12px">
      <div className="d-flex flex-column gap-2">
        <InputGroup
          {...props}
          placeholder="0%"
          unit="%"
          inputClassName="form-control appearance-none py-1 ht-32"
          unitClassName="ht-32"
          inputMode="decimal"
        />
        <PercentButtons
          disabled={disabled}
          value={100}
          buttons={percentButtons}
          onClick={(v) => onChange(new BigNumber(v).minus(100).valueOf())}
        />
        {props.isInValid && (
          <div className="card bg-semi-danger-10 mt-2 pd-12 py-2">
            <span className="tx-13 tx-danger">
              {t(`order.alpha.intermediate-step-${isBuy ? 'buy' : 'sell'}-error`)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(LevelStep);
