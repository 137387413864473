import React, { useMemo } from 'react';

type Variant = 'primary' | 'danger';

interface BadgeIconProps {
  children: React.ReactNode;
  className?: string;
  badgeSize?: number;
  variant?: Variant;
}

const BadgeBG = ({ variant = 'primary', size = 44 }: { variant?: Variant, size: number }) => {
  const color = useMemo(() => {
    switch (variant) {
      case 'danger':
        return '#FF5D47';
      default:
        return '#00DBFF';
    }
  }, [variant]);

  return (
    <svg width={size} height={size} viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 12C0.5 5.37258 5.87258 0 12.5 0H32.5C39.1274 0 44.5 5.37258 44.5 12V32C44.5 38.6274 39.1274 44 32.5 44H12.5C5.87258 44 0.5 38.6274 0.5 32V12Z"
        fill={color}
        fillOpacity="0.08"
      />
    </svg>
  );
};

const BadgeIcon = ({ children, className = '', badgeSize = 44, variant }: BadgeIconProps) => {
  return (
    <div className={`badge-icon tx-center ${className}`}>
      <BadgeBG variant={variant} size={badgeSize} />
      <div className="badge-icon__icon">
        {children}
      </div>
    </div>
  );
};

export default BadgeIcon;
