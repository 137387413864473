import { HistoryData } from 'types/whale-signals/whale-signals.request';

export const anomalyHourDiff = (history: HistoryData<number>[]) => {
  const entry = history[0];
  const oneHourAgo = new Date(new Date(entry.date).getTime() - 60 * 60 * 1000);
  const candidates = history.filter(
    (item) => item.date < entry.date && item.date >= oneHourAgo,
  );
  const secondEntry =
    candidates.length > 0 ? candidates[0] : history[history.length - 1];

  return entry.value - secondEntry.value;
};
