import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { QuickMode } from '../../../types';
import FormattedNumber from '@components/common/FormattedNumber';
import { chainToken } from '@helpers/chains';
import LabelValueCard from '@components/common/LabelValueCard';
import TpSlPreview from '@pages/QuickTrade/components/TpSlPreview';
import { useTranslation } from 'react-i18next';
import Check from '@components/common/Check';
import { useNavigate } from 'react-router-dom';
import { useStores } from '@hooks/useStores';
import UserAvatar from '@components/common/UserAvatar';
import Toggle from 'react-toggle';

interface QuickTradePresetPreviewProps {
  preset: QuickMode;
  onDelete: () => void;
  onCheck: () => void;
  enabled: boolean;
}

const QuickTradePresetPreview = observer(({ preset, onDelete, onCheck, enabled }: QuickTradePresetPreviewProps) => {
  const { blockchain, id, name, valueIn, orders, orderSettings, walletId } = preset;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountStore } = useStores();
  const { wallets } = accountStore;

  const wallet = useMemo(() => {
    return wallets.find((w) => w.id === walletId);
  }, [wallets, walletId]);

  const tpSettings = useMemo(() => {
    return orders.takeProfits?.[0]?.settings;
  }, [orders.takeProfits]);

  const slSettings = useMemo(() => {
    return orders.stopLosses?.[0]?.settings;
  }, [orders.stopLosses]);

  return (
    <div className={`card py-2 px-2 bg-transparent mb-3 border border-solid ${enabled ? 'border-primary' : 'border-semi-transparent'}`}>
      <div className="d-flex justify-content-between align-items-center tx-14 mb-2">
        <Toggle
          icons={false}
          className="styled-toggle"
          checked={enabled}
          onChange={onCheck}
        />
        <div className="ms-2 me-auto">{name}</div>
        <FormattedNumber
          value={valueIn}
          postfix={chainToken(blockchain)}
          subZeros
          className="me-2"
        />
        <UserAvatar string={wallet?.address} size={20} />
      </div>
      <div className="d-flex justify-content-between align-items-center gap-2">
        <LabelValueCard
          label={t('trading.slippage')}
          value={orderSettings.slippage || 5}
          unit="%"
        />
        <LabelValueCard
          label={t('trading.tp-slippage')}
          value={tpSettings?.slippage || 5}
          unit="%"
        />
        <LabelValueCard
          label={t('trading.sl-slippage')}
          value={slSettings?.slippage || 5}
          unit="%"
        />
      </div>

      {(!!orders?.takeProfits?.length || !!orders.stopLosses?.length) && (
        <div
          className="d-flex flex-row bg-semi-transparent-10 p-2 justify-content-between align-items-start gap-2 mt-2 card">
          {!!orders.takeProfits?.length && (
            <div>
              {orders.takeProfits.map((tp, idx) => (
                <TpSlPreview value={tp} key={`preset-tp-${id}-${idx}`} />
              ))}
            </div>
          )}
          {!!orders.stopLosses?.length && (
            <div>
              {orders.stopLosses.map((tp, idx) => (
                <TpSlPreview value={tp} key={`preset-tp-${id}-${idx}`} />
              ))}
            </div>
          )}
        </div>
      )}

      <div className="d-flex justify-content-between gap-2 mt-2 z-index-10">
        <button
          className="btn btn-sm tx-12 btn-secondary wd-100p"
          onClick={() => navigate(`/quick-trade/${id}`)}
        >
          {t('common.edit')}
        </button>
        <button
          className="btn btn-sm tx-12 btn-danger wd-100p bg-semi-danger-10"
          onClick={onDelete}
        >
          {t('common.delete')}
        </button>
      </div>

      <div className="pos-absolute absolute-item z-index-5 cur-pointer" onClick={onCheck} />
    </div>
  );
});

export default QuickTradePresetPreview;
