import React from 'react';

interface CheckProps {
  checked: boolean;
  className?: string;
}

const Check = ({ checked, className = '' }: CheckProps) => {
  return (
    <div className={`check ${checked ? 'checked' : ''} ${className}`} />
  );
};

export default Check;
