import { action, makeAutoObservable, observable, reaction } from 'mobx';
import { Socket } from 'socket.io-client';
import { SocketStore } from 'stores/socket-store';
import { OrdersStore } from 'stores/orders-store';
import api from '@helpers/api';
import {
  WhaleSignalPreviewResponse,
  WhaleSignalResponse,
  WhaleSignalsInviteLinksResponse,
} from 'types/whale-signals/whale-signals.request';
import { Blockchain } from 'types/enums';

enum Events {
  SUBSCRIBE_WHALE_SIGNALS = 'SUBSCRIBE_WHALE_SIGNALS',
  UNSUBSCRIBE_WHALE_SIGNALS = 'UNSUBSCRIBE_WHALE_SIGNALS',
}

export class WhaleSignalsStore {
  @observable
  private _socketStore: SocketStore;

  @observable
  private _whaleSignalsPreview: WhaleSignalPreviewResponse[];

  @observable
  private _whaleSignalsInviteLinks: Record<string, string>;

  @observable
  private _isSignalsSubscribed: boolean;

  @observable
  private _signals: WhaleSignalResponse[];

  constructor(socketStore: SocketStore, ordersStore: OrdersStore) {
    this._socketStore = socketStore;

    makeAutoObservable(this);

    reaction(
      () => socketStore.isOnline,
      (isOnline) => {
        if (!isOnline) {
          this.socket.disconnect();
          this.socket.connect();
          this.subscribeWhaleSignals();
        }
      },
    );
  }

  get blockchain() {
    return Blockchain.ETHEREUM;
  }

  get whaleSignalsPreview() {
    return (this._whaleSignalsPreview ?? []).slice(0, 3);
  }

  get socket() {
    return this._socketStore.socket;
  }

  get isSignalsSubscribed() {
    return this._isSignalsSubscribed;
  }

  get signals() {
    return this._signals ?? [];
  }

  get inviteLink() {
    return this._whaleSignalsInviteLinks
      ? this._whaleSignalsInviteLinks[this.blockchain]
      : '';
  }

  @action
  setSignals(list: typeof this.signals) {
    this._signals = list;
  }

  @action
  subscribeWhaleSignals() {
    if (!this.socket) return;
    this.socket.on(
      Events.SUBSCRIBE_WHALE_SIGNALS,
      (data: typeof this.signals) => {
        this._isSignalsSubscribed = true;

        const dataAdresses = data.map(({ token: { address } }) => address);
        const filteredState = this.signals.filter(
          ({ token: { address } }) => !dataAdresses.includes(address),
        );
        const updatedSignals = [...data, ...filteredState].slice(0, 20);

        this.setSignals(updatedSignals);
      },
    );

    this.socket.emit(Events.SUBSCRIBE_WHALE_SIGNALS, {
      blockchain: this.blockchain,
    });
  }

  @action
  unsubscribeWhaleSignals() {
    this._isSignalsSubscribed = false;
    this._signals = [];
    this.socket.off(Events.SUBSCRIBE_WHALE_SIGNALS);
    this.socket.emit(Events.UNSUBSCRIBE_WHALE_SIGNALS);
  }

  @action
  async getWhaleSignalsPreview() {
    try {
      const res = await api<WhaleSignalPreviewResponse[]>({
        method: 'get',
        path: '/whale/preview',
        data: {
          blockchain: this.blockchain,
        },
      });

      this._whaleSignalsPreview = res;
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  @action
  async getWhaleSignalsChannelLinks() {
    try {
      const res = await api<WhaleSignalsInviteLinksResponse[]>({
        method: 'get',
        path: '/whale/invite-links',
      });
      this._whaleSignalsInviteLinks = res.reduce(
        (acc: typeof this._whaleSignalsInviteLinks, item) => {
          acc[item.blockchain] = item.link;
          return acc;
        },
        {},
      );

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
