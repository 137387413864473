import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import evm from '@assets/images/evm.svg';
import solana from '@assets/images/solana.svg';
import tron from '@assets/images/tron.svg';
import ton from '@assets/images/ton.svg';
import bitcoin from '@assets/images/bitcoin.svg';
import sui from '@assets/images/chains/sui.svg';
import { WalletType } from '../../types/enums';
import Badge from '@components/common/Badge';
import { useTranslation } from 'react-i18next';
import WhatIsEvmModal from '@pages/Wallet/components/WhatIsEvmModal';

const options = [
  {
    title: 'EVM Chains',
    icon: evm,
    type: WalletType.EVM,
  },
  {
    title: 'SUI',
    icon: sui,
    type: WalletType.SUI,
    label: 'common.new',
  },
  {
    title: 'Tron',
    icon: tron,
    type: WalletType.TRON,
  },
  {
    title: 'Solana',
    icon: solana,
    type: WalletType.SOL,
  },
  {
    title: 'TON',
    icon: ton,
    type: WalletType.TON,
  },
  {
    title: 'Bitcoin',
    icon: bitcoin,
    type: WalletType.BTC,
    label: 'common.soon',
  },
];

const NewWallet = observer(() => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="tx-center full-page d-flex flex-column">
      <h1 className="tx-24 mt-5">{t('wallet.choose-network')}</h1>

      <div className="tx-muted tx-14 mb-5 px-4">
        {t('wallet.choose-network-desc')}
      </div>

      <div>
        {options.map(({ title, icon, label, type }, index) => (
          <button
            className={`btn d-flex align-items-center justify-content-start px-3 py-3 wd-100p mb-3 tx-17 tx-semibold ${label === 'common.soon' ? 'opacity-50 btn-outline border-semi-transparent-10' : 'btn-semi-10'}`}
            onClick={(e) => {
              const target = e.target as HTMLElement;
              if (
                label !== 'common.soon' &&
                target.id !== 'evm-modal' &&
                target.getAttribute('aria-modal') !== 'true'
              ) {
                navigate(`/wallet/new/operation`, {
                  state: { operation: state, type },
                });
              }
            }}
            key={`type-selection-item-${index}`}
          >
            <img src={icon} alt={title} className="ht-30 me-3" />

            <div className="tx-17">{title}</div>

            {type === WalletType.EVM && <WhatIsEvmModal />}
            {label && <Badge label={t(label)} className="tx-12 ms-auto me-0" />}
          </button>
        ))}
      </div>
    </div>
  );
});

export default NewWallet;
