import React, { useEffect, useMemo, useState } from 'react';
import { To, useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import BigNumber from 'bignumber.js';
import useResponseHandler from '@hooks/useResponseHandler';
import PageLoader from '@components/PageLoader';
import TradeHeader from '@pages/LimitOrders/components/TradeHeader';
import OrderValue from '@pages/LimitOrders/components/OrderValue';
import SellOrderValue from '@pages/LimitOrders/components/SellOrderValue';
import TradeOrderTrigger from '@pages/LimitOrders/components/TradeOrderTrigger';
import IconWithLoading from '@components/common/IconWithLoading';
import { Blockchain } from '../../types/enums';
import useNotification from '@hooks/useNotification';
import TradeOrderType from '@pages/LimitOrders/components/TradeOrderType';
import TradeWalletHeader from '@pages/LimitOrders/components/TradeWalletHeader';
import AlphaOrder from '@pages/LimitOrders/components/AlphaOrder';
import { hasOwnProperty } from '@helpers/object';
import Vibrutton from '@components/common/Vibrutton';
import { useTranslation } from 'react-i18next';
import { TokenParams, TradeParams } from '@stores/token-store';
import { OrderTransactionType } from 'types/order/order.enum';
import { defaultOrderSettings } from '@stores/settings-store';
import { WalletBalance } from 'types';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import confirmAlert from '@components/ConfirmAlert';

interface LimitOrdersTradeProps {
  isUpdate?: boolean;
}

const LimitOrdersTrade = observer(({ isUpdate }: LimitOrdersTradeProps) => {
  const { t } = useTranslation();
  const [w] = useAmplitude([AmplitudeEvent.ORDER_CREATION_SCREEN_VIEWED]);
  const navigate = useNavigate();
  const handleResponse = useResponseHandler();
  const notify = useNotification();

  const params = useParams();
  const { state } = useLocation();

  const { accountStore, ordersStore, settingsStore, tokenStore, tokenTrade } =
    useStores();

  const { currentWallet } = accountStore;
  const { balance } = ordersStore;
  const {
    gas,
    tokenBalance,
    tokenDetails: token,
    tokenBalanceFormatted,
  } = tokenStore;

  const {
    error,
    chain,
    currency,
    orderInit,
    isBuy,
    isMarket,
    isLimit,
    isStopLoss,
    isAlpha,
    order,
    orderType,
    buyAmount,
    buyAmountUsd,
    sellAmount,
    sellAmountUsd,
    triggerAmount,
    triggerType,
    tpSlOrders,
    alphaOrder,
    hasAlphaOrderError,
  } = tokenTrade;

  const { orderSettings, amounts, percents } = settingsStore;
  const settings = (orderSettings ?? { [chain!]: defaultOrderSettings })[
    chain!
  ];

  useEffect(() => {
    if (error) {
      handleResponse(t(error.message), true);
      error?.navigate && navigate(error.navigate as To);
    }
  }, [error]);

  useEffect(() => {
    state?.token && tokenStore.setTokenDetails(state.token);
    tokenStore.setRouteParams(params as TokenParams);
    tokenTrade.setRouteParams(params as TradeParams);
    return () => {
      tokenStore.reset();
      tokenTrade.reset();
    };
  }, []);

  useEffect(() => {
    tokenTrade.initialize();
  }, [order, currency, tokenBalance, balance, orderInit]);

  useEffect(() => {
    if (
      state?.action &&
      state.action.toUpperCase() === OrderTransactionType.SELL
    ) {
      tokenTrade.setBuySell(OrderTransactionType.SELL);
    }
  }, [state]);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isLiquidityWarning = useMemo(() => {
    if (!token?.liquidity) return false;
    if ((token.liquidity?.usd || 0) <= 10000) return true;
    const amount = new BigNumber((isBuy ? buyAmountUsd : sellAmountUsd) || 0);
    const percents = amount
      .dividedBy(token.liquidity.usd || 0)
      .multipliedBy(100);
    return percents.isGreaterThanOrEqualTo(5);
  }, [token]);

  const isMaxGasWarning = useMemo(() => {
    return (
      isMarket &&
      settings?.maxGasPrice &&
      settings.maxGasPrice < Number(gas?.gwei)
    );
  }, [gas, settings?.maxGasPrice, isMarket]);

  const showMaxGasAlert = () =>
    confirmAlert({
      title: t('order.max-gas-warning-title'),
      message: (
        <div className="tx-warning tx-semibold py-3 ">
          {t('order.max-gas-warning-description')}
        </div>
      ),
    }).then((result) => {
      if (result === 'confirm') {
        handleSubmit({ forceMaxGas: true });
      }
    });

  const handlePreSubmit = () => {
    if (isLiquidityWarning) {
      confirmAlert({
        title: t('order.liquidity-warning-title'),
        message: (
          <div className="tx-warning tx-semibold py-3 ">
            {t('order.liquidity-warning-description')}
          </div>
        ),
      }).then((result) => {
        if (result === 'confirm') {
          isMaxGasWarning ? showMaxGasAlert() : handleSubmit();
        }
      });
    } else if (isMaxGasWarning) {
      showMaxGasAlert();
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = (
    { forceMaxGas }: { forceMaxGas: boolean } = { forceMaxGas: false },
  ) => {
    if (!token) return;
    const value = new BigNumber((isBuy ? buyAmount : sellAmount) || 0);

    tokenTrade.setEmptyInputsLightOn();

    if (value.isLessThanOrEqualTo(0)) {
      notify(t('order.enter-valid-amount'), { type: 'danger' });
      return;
    }

    if (chain === Blockchain.TRON && isBuy) {
      const diff = new BigNumber(balance!.balance).minus(value).toNumber();

      if (diff < 100) {
        return notify(t('errors.trade.balance-less-100-trx'), {
          type: 'danger',
        });
      }
    }

    if (
      (isLimit || isStopLoss) &&
      (!triggerAmount || new BigNumber(triggerAmount).isLessThanOrEqualTo(0))
    ) {
      notify(t('order.enter-valid-trigger-price'), { type: 'danger' });
      return;
    }

    if (isBuy && isMarket && value.isGreaterThan(balance?.balance || 0)) {
      notify(t('order.insufficient-balance'), { type: 'danger' });
      return;
    }

    if (
      !isBuy &&
      isMarket &&
      value.isGreaterThan(tokenBalance?.tokenBalance || 0)
    ) {
      notify(t('order.insufficient-balance'), { type: 'danger' });
      return;
    }

    if (
      tokenTrade.stopLossesError.some((e) => e) ||
      tokenTrade.takeProfitsError.some((e) => e)
    ) {
      return notify(t('order.invalid-tp-sl-data'), {
        type: 'danger',
      });
    }

    if (hasAlphaOrderError) {
      return notify(t('order.alpha.invalid-alpha-order-data'), {
        type: 'danger',
      });
    }

    setIsSaving(true);

    try {
      if (order) {
        ordersStore
          .updateOrder({
            alphaOrder,
            tpSlOrders,
            settings: {
              ...settings,
              extraGasPricePercent:
                settings?.extraGasPricePercent !== null
                  ? settings?.extraGasPricePercent
                  : undefined,
              slippage:
                settings?.slippage !== null ? settings?.slippage : undefined,
            },
            targetTriggerValue:
              (isLimit || isStopLoss) && triggerAmount
                ? new BigNumber(triggerAmount).toString()
                : undefined,
            valueIn: value.toString(),
            orderId: order.id,
          })
          .then((response) => {
            setIsSaving(false);
            if (response && hasOwnProperty(response, 'id')) {
              navigate(
                `/limit-orders/${order.blockchain}/${order.pairAddress}/${order.pairId}`,
                { replace: true },
              );
              notify(t('order.order-update-success'), { type: 'success' });
            } else {
              handleResponse(
                response?.data?.message || t('error-occurred'),
                true,
              );
            }
          })
          .catch((e) => {
            console.error(e);
            setIsSaving(false);
            handleResponse(e.response);
          });
        return;
      }
    } catch (e) {
      console.error('UpdateOrder Error: ', e);
      notify(String(e), { type: 'danger' });
      setIsSaving(false);
    }

    try {
      ordersStore
        .createOrder({
          alphaOrder,
          tpSlOrders,
          settings: {
            ...settings,
            extraGasPricePercent:
              settings?.extraGasPricePercent !== null
                ? settings?.extraGasPricePercent
                : undefined,
            slippage:
              settings?.slippage !== null ? settings?.slippage : undefined,
            maxGasPrice: forceMaxGas
              ? 10000
              : settings?.maxGasPrice !== null
                ? settings?.maxGasPrice
                : undefined,
          },
          blockchain: chain?.toUpperCase() as Blockchain,
          type: orderType,
          trigger:
            (isLimit || isStopLoss || isAlpha) && triggerType
              ? triggerType
              : undefined,
          targetTriggerValue:
            (isLimit || isStopLoss) && triggerAmount
              ? new BigNumber(triggerAmount).toString()
              : undefined,
          pairAddress: token.pairAddress,
          valueIn: value.toString(),
          walletId: currentWallet!.id,
        })
        .then((response) => {
          setIsSaving(false);
          if (response && hasOwnProperty(response, 'pairAddress')) {
            navigate(
              `/limit-orders/${response.blockchain}/${response.pairAddress}/${response.pairId}`,
              { replace: true },
            );
            notify(t('order.order-success'), { type: 'success' });
          } else {
            handleResponse(
              response?.data?.message || t('error-occurred'),
              true,
            );
          }
        })
        .catch((e) => {
          console.error(e);
          setIsSaving(false);
          handleResponse(e.response);
        });
    } catch (e) {
      console.error('CreateOrder Error: ', e);
      notify(String(e), { type: 'danger' });
      setIsSaving(false);
    }
  };

  if (!token || !orderInit) {
    return <PageLoader />;
  }

  return (
    token && (
      <div className="pt-4 pb-5">
        <TradeWalletHeader className="mb-3" filterByType />

        <TradeHeader />

        <TradeOrderType />

        {isLimit && <TradeOrderTrigger />}

        {isStopLoss && <TradeOrderTrigger />}

        {isAlpha && <AlphaOrder />}

        {isBuy && (
          <OrderValue
            balance={balance}
            amount={buyAmount}
            amountChange={(v) => tokenTrade.setBuyAmount(v)}
            amountUsd={buyAmountUsd}
            amountUsdChange={(v) => tokenTrade.setBuyAmountUsd(v)}
            predefined={amounts}
            isPredefinedUsd
          />
        )}

        {!isBuy && isMarket && (
          <OrderValue
            balance={tokenBalanceFormatted as WalletBalance}
            amount={sellAmount}
            amountChange={(v) => tokenTrade.setSellAmount(v)}
            amountUsd={sellAmountUsd}
            amountUsdChange={(v) => tokenTrade.setSellAmountUsd(v)}
            predefined={percents}
          />
        )}

        {!isBuy && !isMarket && <SellOrderValue />}

        <Vibrutton
          className={`btn ${isLiquidityWarning || isMaxGasWarning ? 'btn-warning' : 'btn-primary'} wd-100p mt-5`}
          onClick={w(
            handlePreSubmit,
            order
              ? AmplitudeEvent.UPDATE_ORDER_BUTTON_CLICKED
              : AmplitudeEvent.CREATE_ORDER_BUTTON_CLICKED,
            (() => {
              const nativePrice = isBuy
                ? new BigNumber(buyAmount!)
                : new BigNumber(sellAmount!).multipliedBy(
                    token.priceInBaseToken,
                  );
              const usdPrice = nativePrice.multipliedBy(currency!).toNumber();
              return {
                isAlpha,
                type: orderType,
                blockchain: chain,
                nativePrice,
                usdPrice,
              };
            })(),
          )}
          disabled={isSaving}
          vibrate="medium"
        >
          <IconWithLoading
            isLoading={isSaving}
            icon={
              isLiquidityWarning || isMaxGasWarning
                ? faExclamationTriangle
                : undefined
            }
            className="me-2"
          />
          {t(
            order
              ? 'order.update-order'
              : isMarket
                ? 'common.swap'
                : 'order.create-order',
          )}
        </Vibrutton>

        <button
          className="btn btn-transparent wd-100p mt-2 mb-5"
          onClick={() => {
            navigate(-1);
          }}
          disabled={isSaving}
        >
          {t('common.cancel')}
        </button>
      </div>
    )
  );
});

export default LimitOrdersTrade;
