import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@hooks/useStores';
import { useNavigate, useParams } from 'react-router-dom';
import useResponseHandler from '@hooks/useResponseHandler';
import PageLoader from '@components/PageLoader';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import NetworkSelectionPill from '@components/NetworkSelectionPill';
import { ChainId, PageRoutes } from '../../constants';
import IconWithLoading from '@components/common/IconWithLoading';
import { Blockchain } from '../../types/enums';
import useNotification from '@hooks/useNotification';
import QuickTradePresetPreview from '@pages/QuickTrade/components/QuickTradePresetPreview';
import DeletePresetModal from '@pages/QuickTrade/components/DeletePresetModal';
import { QuickMode } from '../../types';
import presets from '@assets/images/presets.svg';
import plus from '@assets/icons/plus.svg';
import { hasOwnProperty } from '@helpers/object';

const QuickTrade = observer(() => {
  const { t } = useTranslation();
  const handleResponse = useResponseHandler();
  const params = useParams();
  const notify = useNotification();
  const navigate = useNavigate();
  useAmplitude([AmplitudeEvent.QUICK_TRADE_PAGE_VIEWED, { params }]);

  const { accountStore, quickTradeStore } = useStores();

  const { network } = accountStore;
  const { quickTradeSettings, quickTradeSettingsLoaded } = quickTradeStore;

  const [deletePreset, setDeletePreset] = useState<QuickMode | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const enabledSettings = useMemo(() => {
    return quickTradeSettings.filter((s) => s.enabled);
  }, [quickTradeSettings]);

  const networkSettings = useMemo(() => {
    return quickTradeSettings.length
      ? quickTradeSettings.filter((s) => s.blockchain === (network as unknown as Blockchain)).sort((a,b) => new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1)
      : null;
  }, [quickTradeSettings, network]);

  const serverEnabled = useMemo(() => {
    return networkSettings?.find((s) => s.enabled)?.id;
  }, [networkSettings]);

  const [enabled, setEnabled] = useState<string | null>('');
  const [isInit, setIsInit] = useState<boolean>(false);

  useEffect(() => {
    setIsInit(false);
    setEnabled(null);
  }, [network]);

  useEffect(() => {
    if (!isInit && enabled === null && serverEnabled) {
      setEnabled(serverEnabled);
    }
  }, [isInit, enabled, serverEnabled]);

  const handleSave = () => {
    if (!quickTradeSettingsLoaded || !networkSettings?.length) return;

    let found = networkSettings.find((s) => s.id === enabled);
    let prop = true;
    if (!enabled && serverEnabled) {
      found = networkSettings.find((s) => s.id === serverEnabled);
      prop = false;
    }

    if (!found) return;

    setIsSaving(true);
    quickTradeStore.updatePreset({ ...found, enabled: prop }).then((response) => {
      setIsSaving(false);
      if (Array.isArray(response) && !hasOwnProperty(response, 'success')) {
        notify(t(`quick-trade.settings-${prop ? 'enabled' : 'disabled'}`), { type: 'success' });
      } else {
        handleResponse(response, true);
      }
    }).catch((e) => {
      setIsSaving(false);
      handleResponse(e, true);
    });
  };

  const handleDisable = () => {
    if (!quickTradeSettingsLoaded || !networkSettings?.length) return;

    const found = networkSettings.find((s) => s.enabled);

    if (!found) return;

    setIsSaving(true);
    quickTradeStore.updatePreset({ ...found, enabled: false }).then((response) => {
      setIsSaving(false);
      setEnabled('')
      if (Array.isArray(response) && !hasOwnProperty(response, 'success')) {
        notify(t('quick-trade.settings-disabled'), { type: 'success' });
      } else {
        handleResponse(response, true);
      }
    }).catch((e) => {
      setIsSaving(false);
      handleResponse(e, true);
    });
  };

  if (!quickTradeSettingsLoaded) {
    return <PageLoader />;
  }

  return (
    <div className="pt-4 pb-5 d-flex flex-column">
      <h1 className="my-3">
        {t('quick-trade.title')}
      </h1>

      <NetworkSelectionPill highlight={enabledSettings.map((s) => s.blockchain as unknown as ChainId)} displayAll />

      <div className="card mt-3">
        <div className="tx-14">{t('quick-trade.buy-presets')}</div>
        <div className="tx-12 tx-muted mt-1 mb-3">
          {t('quick-trade.buy-presets-description')}
        </div>

        {!!networkSettings?.length && (
          <>
            {networkSettings.map((p) => (
              <QuickTradePresetPreview
                preset={p}
                onDelete={() => setDeletePreset(p)}
                onCheck={() => setEnabled((prev) => prev === p.id ? '' : p.id)}
                key={`preset-preview-${p.id}`}
                enabled={enabled === p.id}
              />
            ))}

            <button
              className="btn btn-sm btn-secondary wd-100p tx-12 tx-lowercase"
              onClick={() => navigate(PageRoutes.QUICK_TRADE_NEW)}
            >
              <img src={plus} alt="Add" className="me-1" width={16} />
              {t('quick-trade.add-preset')}
            </button>
          </>
        )}
        {!networkSettings?.length && (
          <div className="card ht-200 align-items-center justify-content-center gap-2 bd-1 border-semi-transparent bd-dashed">
            <img src={presets} alt="Presets" />
            <div className="tx-14 tx-muted">
              {t('quick-trade.no-presets')}
            </div>
            <button
              className="btn btn-sm px-3 py-2 btn-secondary tx-12"
              onClick={() => navigate(PageRoutes.QUICK_TRADE_NEW)}
            >
              {t('quick-trade.create-preset')}
            </button>
          </div>
        )}
      </div>

      {!!networkSettings?.length && (!serverEnabled || enabled !== serverEnabled) && (
        <button
          className="btn btn-primary mt-3"
          onClick={handleSave}
          disabled={isSaving}
        >
          <IconWithLoading isLoading={isSaving} className="me-2" />
          {t('common.save')}
        </button>
      )}
      {serverEnabled && enabled === serverEnabled && (
        <button
          className="btn btn-secondary mt-3"
          onClick={handleDisable}
          disabled={isSaving}
        >
          <IconWithLoading isLoading={isSaving} className="me-2" />
          {t('common.disable')}
        </button>
      )}

      <DeletePresetModal preset={deletePreset} onClose={() => setDeletePreset(null)} />
    </div>
  );
});

export default QuickTrade;
