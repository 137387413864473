import React, { ReactNode, useEffect, useMemo } from 'react';
import { Router } from 'react-router-dom';
import { initNavigator } from '@telegram-apps/sdk-react';
import { useIntegration } from '@telegram-apps/react-router-integration';

interface AppRouterProps {
  children?: ReactNode;
}

const AppRouter = ({children}: AppRouterProps) => {
  // Create a new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = useMemo(() => initNavigator('app-navigation-state', { hashMode: null }), []);
  const [location, reactNavigator] = useIntegration(navigator);

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  // IDK why, but this crutch fixes routing on first loading
  useEffect(() => {
    if (navigator.pathname !== location.pathname)
      navigator.replace(navigator.pathname);
  }, [location]);

  return (
    <Router location={location} navigator={reactNavigator}>
      {children}
    </Router>
  );
};

export default AppRouter;
