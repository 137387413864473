import React from 'react';
import info from 'assets/icons/info-circle.svg';
import container from '@assets/icons/container.svg';
import target from '@assets/icons/target.svg';
import { useTranslation } from 'react-i18next';
import {
  PageRoutes,
  sniperScopesLink,
  blockXrayLink,
} from '../../../constants';
import { useNavigate } from 'react-router-dom';
import WhaleSignalAnomalyItem from '@pages/WhaleSignals/components/WhaleSignalAnomalyItem';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import AlphaLink from '@components/common/AlphaLink';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import { useWhaleSignalsInfoModal } from 'pages/WhaleSignals/components/WhaleSignalsInfoModal';

const ResearchWhaleSignals = () => {
  const { whaleSignalsStore } = useStores();
  const { whaleSignalsPreview } = whaleSignalsStore;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showWhaleSignalsInfoModal = useWhaleSignalsInfoModal();
  const [w] = useAmplitude();

  return (
    <>
      <div className="d-flex wd-100p gap-12px mb-3">
        {[
          {
            path: blockXrayLink,
            event: AmplitudeEvent.BLOCK_XRAY_CLICKED,
            label: t('wallet.block-x-ray'),
            icon: container,
          },
          {
            path: sniperScopesLink,
            event: AmplitudeEvent.SNIPE_SCOPE_CLICKED,
            label: t('common.snipe-scope'),
            icon: target,
          },
        ].map(({ path, event, label, icon }) => (
          <AlphaLink
            key={event}
            path={path}
            className="wd-100p text-decoration-none"
          >
            <button
              onClick={w(() => null, event)}
              className="px-0 d-flex align-items-center justify-content-center wd-100p btn btn-semi-25 gap-2"
            >
              <img src={icon} alt="Container" />
              <span className="tx-normal">{label}</span>
            </button>
          </AlphaLink>
        ))}
      </div>
      <div className="card">
        <div className="d-flex wd-100p mb-3 justify-content-between">
          <span className="tx-semibold">🐋 {t('common.whale-signals')}</span>
          <button
            className="btn p-0 d-flex justify-content-center"
            onClick={showWhaleSignalsInfoModal}
          >
            <img src={info} alt="Info" />
          </button>
        </div>

        {whaleSignalsPreview.map((signal) => (
          <WhaleSignalAnomalyItem
            className="pd-y-12"
            key={signal.token.address}
            {...signal}
          />
        ))}

        <button
          className="wd-100p btn btn-semi-10 mt-2"
          onClick={() => navigate(PageRoutes.WHALE_SIGNALS)}
        >
          {t('common.show-all')}
        </button>
      </div>
    </>
  );
};

export default observer(ResearchWhaleSignals);
