import React, { useEffect, useMemo } from 'react';
import cross from '@assets/icons/cross.svg';
import NumericInput from '@components/common/NumericInput';
import BigNumber from 'bignumber.js';

interface InputGroupProps {
  value: string | number | null;
  className?: string;
  inputClassName?: string;
  unitClassName?: string;
  isNumeric?: boolean;
  asString?: boolean;
  placeholder?: string;
  unit?: string;
  onChange: (v: string | number | null) => void;
  disabled?: boolean;
  isInValid?: boolean;
  inputMode?: 'decimal' | 'text';
  noCross?: boolean;
}

const InputGroup = (props: InputGroupProps) => {
  const {
    value,
    className = '',
    inputClassName = 'form-control appearance-none',
    unitClassName = '',
    isNumeric = false,
    placeholder = '',
    unit,
    disabled,
    isInValid,
    noCross,
    inputMode,
    onChange,
  } = props;
  const randomId = useMemo(
    () => Math.random().toString(36).substring(7) + '' + Date.now(),
    [],
  );

  const changeHandler = (v: string | number | null) => {
    const formattedVal =
      typeof v === 'string' && inputMode === 'decimal'
        ? v.replace(/(?!^)-|[^0-9,.-]+/g, '').replace(',', '.')
        : v;

    onChange(formattedVal);
  };

  const unitOrCross = useMemo(() => {
    return value !== undefined && value !== null && (!!unit || !noCross);
  }, [value, unit, noCross]);

  useEffect(() => {
    const input = document.getElementById(randomId);
    const prevent = (e: any) => e.preventDefault();
    input?.addEventListener('mousewheel', prevent);
    return () => input?.removeEventListener('mousewheel', prevent);
  }, [randomId]);

  return (
    <div
      className={`input-group ${isInValid ? 'is-invalid' : ''} ${className}`}
    >
      {!isNumeric && (
        <input
          type={isNumeric ? 'number' : 'text'}
          inputMode={inputMode}
          className={`${inputClassName} ${isInValid ? 'is-invalid' : ''} ${unitOrCross ? 'pe-0' : ''}`}
          placeholder={placeholder}
          value={value === undefined || value === null ? '' : value}
          onChange={(e) => {
            changeHandler(e.target.value !== '' ? e.target.value : null);
          }}
          disabled={disabled}
          id={randomId}
          onBlur={() => window.scrollTo(0, 0)}
        />
      )}
      {isNumeric && (
        <NumericInput
          {...props}
          value={value !== null ? new BigNumber(value).toString() : null}
          className={`${inputClassName} ${unitOrCross ? 'pe-0' : ''}`}
        />
      )}
      {unitOrCross && (
        <div className={`input-group-text ${unitClassName}`}>
          {!!unit && <span className="me-1">{unit}</span>}
          {!(noCross || disabled) && (
            <img src={cross} alt="Clear" onClick={() => onChange(null)} />
          )}
        </div>
      )}
    </div>
  );
};

export default InputGroup;
