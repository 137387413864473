import { Variants } from 'framer-motion/dist/index';
import { Blockchain } from './types/enums';
import { allowedChains } from '@helpers/chains';

export const ENV = {
  API_URL: process.env.REACT_APP_API_URL as string,
  DEX_API_URL: process.env.REACT_APP_DEX_API_URL as string,
  DEX_WSS_URL: process.env.REACT_APP_DEX_WSS_URL as string,
  WHALE_LOCATOR_URL: process.env.REACT_APP_WHALE_LOCATOR_API_URL as string,
  BOT_URL: process.env.REACT_APP_BOT_URL as string,
};

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'March',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export enum PageRoutes {
  HOME = '/',
  WALLET = '/wallet',
  RESEARCH = '/research',
  PROFILE = '/profile',
  LOGIN = '/login',
  CREATE_WALLET = '/wallet/create',
  IMPORT_PIN = '/wallet/import-pin',
  IMPORT_WALLET = '/wallet/import',
  WALLET_CREATED = '/wallet/created',
  GET_SEED_PHRASE = '/wallet/get-seed-phrase',
  SEED_PHRASE = '/wallet/seed-phrase',
  WALLET_READY = '/wallet/ready',
  ASSET = '/asset/:address',
  RECEIVE = '/receive',
  SEND = '/send',
  SEND_CONFIRM = '/send/confirm',
  SWAP = '/swap',
  TOKEN_SNIPE = '/token-snipe',
  TOKEN_SNIPE_POSITION = '/token-snipe/:address',
  TOKEN_SNIPE_POSITION_BOT = '/token-snipe-bot/:address',
  AUTO_SNIPE = '/auto-snipe',
  LIMIT_ORDERS = '/limit-orders',
  LIMIT_ORDERS_HIDDEN = '/limit-orders/hidden',
  LIMIT_ORDERS_TOKEN = '/limit-orders/:chain/:address/:pairId',
  LIMIT_ORDERS_TRADE = '/limit-orders/:chain/:address/trade',
  LIMIT_ORDERS_UPDATE = '/limit-orders/:chain/:address/update/:id',
  QUICK_TRADE = '/quick-trade',
  QUICK_TRADE_NEW = '/quick-trade/new',
  QUICK_TRADE_EDIT = '/quick-trade/:id',
  POSITION_PROCESSING = '/position-processing/:id',
  POSITION_PROCESSING_BOT = '/position-processing-bot/:id',
  POSITION_SETTINGS = '/position-settings/:id',
  SETTINGS = '/settings',
  SECURITY = '/settings/security',
  CHANGE_PIN = '/settings/change-pin',
  SNIPE_SETTINGS = '/settings/snipe-settings',
  BOT_SETTINGS = '/settings/bot-settings',
  EXPORT_SEED_PHRASE = '/settings/seed-phrase',
  AMOUNT_SETTINGS = '/settings/amount',
  WALLET_SETTINGS = '/settings/wallet',
  EXPORT_PRIVATE_KEY = '/settings/private-key',
  LANGUAGE = '/settings/language',
  WHALE_SIGNALS = '/whale-locator',
  AUTO_TRADE = '/auto-trade',
  AUTO_TRADE_CREATE = '/auto-trade/new',
  AUTO_TRADE_FILTERS = '/auto-trade/filters',
  AUTO_TRADE_EDIT = '/auto-trade/edit',
  REFERRAL = '/referral',
  NO_SOLANA_WALLET = '/wallet/no-solana',
  NO_TON_WALLET = '/wallet/no-ton',
  NEW_WALLET = '/wallet/new',
  NEW_WALLET_CREATE = '/wallet/new/create',
  NEW_WALLET_IMPORT = '/wallet/new/import',
  NEW_WALLET_KEY = '/wallet/new/key',
  NEW_WALLET_KEY_IMPORT = '/wallet/new/import-key',
  NEW_WALLET_NAME = '/wallet/new/name',
  NEW_WALLET_OPERATION = '/wallet/new/operation',
  BRIDGE = '/bridge',
  BRIDGE_CONFIRM = '/bridge/confirm',
}

export enum Sort {
  ASC = 'asc',
  DESC = 'desc',
  DEFAULT = 'default',
}

export const lipsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies aliquam, nunc nisl aliquet nunc, quis aliquam nis`;

export enum ChainId {
  ALL = 'all',
  ETHER = 'ETHEREUM',
  BASE = 'BASE',
  SOLANA = 'SOLANA',
  BSC = 'BSC',
  POLYGON = 'POLYGON',
  AVALANCHE = 'AVALANCHE',
  ARBITRUM = 'ARBITRUM',
  TON = 'TON',
  TRON = 'TRON',
  SUI = 'SUI',
  // AURORA = 'aurora',
  // OPTIMISM = 'optimism',
  // XDAI = 'xdai'
}

export const ethRegex = new RegExp('^0x[a-fA-F0-9]{40}$');
export const tonRegex = new RegExp('[EU][Q][A-Za-z0-9-_]{46}$');
export const tronRegex = new RegExp('T[A-Za-z1-9]{33}');
export const solanaRegex = new RegExp('[1-9A-HJ-NP-Za-km-z]{32,44}');
export const suiRegex = new RegExp('^0x[a-fA-F0-9]{64}::[a-zA-Z_][a-zA-Z0-9_]*::[a-zA-Z_][a-zA-Z0-9_]*$');
export const suiWalletRegex = new RegExp('0[xX][a-fA-F0-9]{64}');

export const numberRegex = /^-?\d*\.?\d*$/;

export const zeroAddress = '0x0000000000000000000000000000000000000000';
export const zeroHash = zeroAddress.replace('0x', '');

export enum MobilePlatforms {
  ANDROID = 'android',
  IOS = 'ios',
}

const transition = { ease: 'linear', duration: 0.3 };

export const transitionVariants: Variants = {
  enter() {
    return {
      backgroundColor: '#1C1C1C',
      x: 0,
      z: 1,
      transition,
      opacity: 1,
      transitionEnd: {
        // after animation has finished, reset the position to static
        position: 'static',
        opacity: 1,
      },
    };
  },
  initial() {
    return {
      backgroundColor: '#1C1C1C',
      x: '100%',
      z: -1,
      transition,
      // opacity: 0,
    };
  },

  // an updated history.action is provided in AnimatedSwitch via "custom" prop for AnimatePresence
  exit() {
    return {
      backgroundColor: '#1C1C1C',
      x: '-10%',
      z: -1,
      transition,
      opacity: 0,
      transitionEnd: {
        // after animation has finished, reset the position to static
        opacity: 0,
      },
    };
  },
};

// export const transitionVariants: Variants = {
//   enter() {
//     // if isPush is true, this is the eventual destination of the top page (sliding in from the right)
//     // if isPush is false, this is the eventual destination of the bottom page (sliding in from the left)
//     // const isPush = history.action === 'PUSH'
//     const isPush = true;
//
//     return {
//       backgroundColor: '#1C1C1C',
//       x: 0,
//       transition,
//       opacity: 1,
//       transitionEnd: {
//         // after animation has finished, reset the position to static
//         position: 'static',
//         opacity: 1,
//       },
//       // keep top "layer" of animation as a fixed position
//       ...(isPush
//         ? {
//           position: 'fixed',
//           top: 0,
//           right: 0,
//           left: 0,
//           bottom: 0,
//         }
//         : {
//           opacity: 0,
//           // position: 'relative',
//         })
//     }
//   },
//   initial() {
//     // if isPush is true, this is the starting position of top page (sliding in from the right)
//     // if ifPush is false, this is the starting position of bottom page (sliding in from the left)
//     // const isPush = history.action === 'PUSH'
//     const isPush = true;
//
//     return {
//       backgroundColor: '#1C1C1C',
//       x: isPush ? '100%' : '-25%',
//       transition,
//       // keep top "layer" of animation as a fixed position
//       ...(isPush
//         ? {
//           position: 'fixed',
//           top: 0,
//           right: 0,
//           left: 0,
//           bottom: 0,
//           opacity: 0,
//         }
//         : {
//           opacity: 0,
//           boxShadow: isPush ? '0 25px 50px -12px rgba(0, 0, 0, 0.5)' : undefined,
//         })
//     }
//   },
//
//   // an updated history.action is provided in AnimatedSwitch via "custom" prop for AnimatePresence
//   exit(custom) {
//     const action = custom?.action as 'PUSH' | 'POP' || 'PUSH';
//     // if isPop is true, this is the top page being dismissed (sliding to the right)
//     // if isPop is false, this is the botom page being dismissed (sliding to the left)
//     const isPop = action === 'POP'
//
//     return {
//       backgroundColor: '#1C1C1C',
//       x: isPop ? '100%' : '-10%',
//       zIndex: isPop ? 1 : -1,
//       transition,
//
//       // keep top "layer" of animation as a fixed position
//       // this will, however, reset the scroll position of the page being dismissed
//       ...(isPop
//         ? {
//           position: 'fixed',
//           top: 0,
//           right: 0,
//           left: 0,
//           bottom: 0,
//           opacity: 1,
//         }
//         : {
//           opacity: 0,
//           boxShadow: isPop ? '0 25px 50px -12px rgba(0, 0, 0, 0.5)' : undefined,
//
//         })
//     }
//   }
// };

export const blockXrayLink = 'https://t.me/blocks_xray';
export const sniperScopesLink = 'https://t.me/sniper_scopes_eth';

export const portfolioRoutes: string[] = [PageRoutes.WALLET];
export const researchRoutes: string[] = [PageRoutes.RESEARCH];
export const tokenTradeRoutes: string[] = [
  PageRoutes.TOKEN_SNIPE,
  PageRoutes.LIMIT_ORDERS,
];
export const referralRoutes: string[] = [PageRoutes.REFERRAL];
export const profileRoutes: string[] = [PageRoutes.PROFILE];
export const evmChains = [
  Blockchain.ETHEREUM,
  Blockchain.BSC,
  Blockchain.POLYGON,
  Blockchain.AVALANCHE,
  Blockchain.ARBITRUM,
  Blockchain.BASE,
];
export const evmChainsDeprecated = [
  ChainId.ETHER,
  ChainId.BSC,
  ChainId.POLYGON,
  ChainId.AVALANCHE,
  ChainId.ARBITRUM,
  ChainId.BASE,
];
