import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import bell from 'assets/icons/bell-white.svg';
import info from 'assets/icons/info-circle-white.svg';
import WhaleSignalItem from 'pages/WhaleSignals/components/WhaleSignalItem';
import { useStores } from '@hooks/useStores';
import { observer } from 'mobx-react-lite';
import { useWhaleSignalsInfoModal } from 'pages/WhaleSignals/components/WhaleSignalsInfoModal';
import { AmplitudeEvent, useAmplitude } from '@hooks/useAmplitude';
import AlphaLink from '@components/common/AlphaLink';

const WhaleSignals = () => {
  const { whaleSignalsStore } = useStores();
  const { signals, inviteLink, socket } = whaleSignalsStore;
  const { t } = useTranslation();
  const [w] = useAmplitude([AmplitudeEvent.WHALE_LOCATOR_PAGE_VIEWED]);
  const showInfo = useWhaleSignalsInfoModal();

  useEffect(() => {
    whaleSignalsStore.subscribeWhaleSignals();
    whaleSignalsStore.getWhaleSignalsChannelLinks();

    return () => {
      whaleSignalsStore.unsubscribeWhaleSignals();
    };
  }, [socket]);

  return (
    <div className="py-3 mb-5">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="tx-bold mb-0">🐋 {t('common.whale-signals')}</h1>
        <div className="d-flex gap-2">
          <AlphaLink path={inviteLink}>
            <button
              onClick={w(
                () => null,
                AmplitudeEvent.WHALE_LOCATOR_INVITE_LINK_CLICKED,
              )}
              className="d-flex btn btn-semi-25 rounded-circle pd-12"
            >
              <img src={bell} alt="Bell" width={20} height={20} />
            </button>
          </AlphaLink>
          <button
            onClick={showInfo}
            className="d-flex btn btn-semi-25 rounded-circle pd-12"
          >
            <img src={info} alt="Info" width={20} height={20} />
          </button>
        </div>
      </div>

      <div className="d-flex flex-column mt-3 gap-12px">
        {signals.map((signal) => (
          <WhaleSignalItem key={signal.token.address} {...signal} />
        ))}
      </div>
    </div>
  );
};

export default observer(WhaleSignals);
